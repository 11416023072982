import { put, take, fork, call } from "redux-saga/effects";
import { Auth, API } from "aws-amplify";
import * as actions from "./actions";
import * as userActions from "actions/user";
import { getSites, awsAuth, awsLoginData } from "../../config/offlineData";
import dotenv from "dotenv";
import { stopSubmit } from "redux-form";
import { GoogleApiHelper } from "helpers/index";
import qs from 'qs';
import axios from 'axios';

dotenv.load();

/**
 * @author Roman Yephimov <ry@piogroup.net>
 * @desc Makes Sign Up request
 */
function* watchSignIn() {
    while (true) {
        const { request: { email, password, action } } = yield take(actions.SIGN_IN.REQUEST);
        try {
            let AuthData = {}
            if(process.env.REACT_APP_MOCK === 'true'){
                AuthData  = yield awsAuth;
                Object.keys(awsLoginData).map(function(key, index) {
                    localStorage.setItem(key, awsLoginData[key])
                });
            } else {
                AuthData = yield Auth.signIn(email, password);
            }
            const { username, userDataKey, attributes, Session } = AuthData;

           yield put(userActions.setUserData({ username, userDataKey, attributes, Session }));

            let response = [];
            if(process.env.REACT_APP_MOCK === 'true'){
                response  = getSites;
            } else {
                response = yield API.get("auctollo", "/site/get-sites");
            }
            if(response && Array.isArray(response.data) && response.data.length > 0) {
                yield put(userActions.setDomainsData(response.data));
                yield put(actions.signIn.success());
                yield call(action, "/");
            } else {
                yield put(actions.signIn.signUpRedirect());
                yield call(action, "/sign-up/wp-verify");
            }
        } catch (e) {
            yield put(actions.signIn.error((e && e.message) ? e.message : e));
            yield put(stopSubmit("signInComponent", e.message));
        }
    }
}


function* watchSocialSignIn() {
    while (true) {
        const { request : {user, action} } = yield take(actions.SOCIAL_LOGIN.REQUEST);
         const { username, userDataKey, attributes, Session } = user;

           yield put(userActions.setUserData({ username, userDataKey, attributes, Session }));

            let response = [];
            if(process.env.REACT_APP_MOCK === 'true'){
                response  = getSites;
            } else {
                response = yield API.get("auctollo", "/site/get-sites");
            }
            if(response && Array.isArray(response.data) && response.data.length > 0) {
                yield put(userActions.setDomainsData(response.data));
                yield put(actions.signIn.success());
                yield call(action, "/");
                yield window.location.replace(GoogleApiHelper.getAuthUrl());
            } else {
                yield put(actions.signIn.signUpRedirect());
                yield call(action, "/sign-up/wp-verify");
            }
    }
}

export default [
    fork(watchSignIn),
    fork(watchSocialSignIn)
];
