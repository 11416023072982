import React from "react";
import { ButtonSignUp } from "../../../common/components";
import global from "../../../assets/scss/global.module.scss";
import styles from "../SignUp.module.scss";
import type { ButtonsConfigType } from "../buttonsConfigData.js";

const SocialButtons = ({ buttonsConfig, onClickBtn }: {buttonsConfig: ButtonsConfigType}) => {
    return(
        <div className={styles["social-btn-group"]}>
            {
                buttonsConfig.map((config, i) => {
                    return (
                        <div className={`${global["form-row"]} ${styles["form-row"]}`} key={i}>
                            <ButtonSignUp
                                url={config.url}
                                text={config.text}
                                icon={config.icon}
                                color={config.color}
                                type={config.type}
                                onClickBtn={onClickBtn} />
                        </div>
                    );
                })
            }
        </div>
    );
};

export default SocialButtons;
