// @flow
import React from "react";
import { RadioButton } from "../../components";
import global from "../../../assets/scss/global.module.scss";
type RegisterPremiumFormType = {
    setPaymentMethod: Function
}

const RegisterPremiumForm = ({ setPaymentMethod }: RegisterPremiumFormType) => {
    return (
        <form>
            <div className={global["form-row"]}>
                <RadioButton isChecked={false} placeholder="Pay with Paypal" onClick={setPaymentMethod("paypal")}/>
            </div>
            <div className={global["form-row"]}>
                <RadioButton isChecked={false} placeholder="Pay with Credit Card" onClick={setPaymentMethod("creditCard")}/>
            </div>
        </form>
    );
};

export default RegisterPremiumForm;
