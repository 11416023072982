import React from "react";
import { API } from "aws-amplify";
/* eslint-disable */
const TestComponent = () => {
    const onClickHandler = async () => {
        const reqBody = {
            body: { token: 123 },
        };
        try {
            const test = await API.post("auctollo", "/sso/testToken", reqBody);
            eslint
            console.log("test: ", test);
        } catch (e) {
            console.log(e, "testError");
        }

    };
    return (
        <div onClick={onClickHandler}>Click Me</div>
    );
};

export default TestComponent;
/* eslint-enable */
