import React from "react";

const SpeechBublesIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 42 32" width="42" height="32">
            <path
                fill="#1C6E9D"
                d="M16.333 23.347c-2.741 0-5.325-.483-7.593-1.336-2.288 1.754-5.095 2.822-8.077 3.129a.6.6 0 01-.65-.43c-.07-.283.153-.457.374-.665 1.095-1.031 2.423-1.842 2.942-5.307C1.24 16.778 0 14.33 0 11.674 0 5.226 7.313 0 16.333 0s16.334 5.226 16.334 11.674c0 6.452-7.314 11.673-16.334 11.673zm25.307 7.655c-1.017-.937-2.25-1.674-2.731-4.824 5.017-4.612 3.804-11.21-2.747-14.79.002.095.005.19.005.286 0 8.749-9.45 15.456-20.727 15.087 2.78 2.211 6.853 3.606 11.393 3.606 2.546 0 4.945-.439 7.052-1.214 2.124 1.594 4.73 2.566 7.5 2.844.275.028.536-.141.602-.39.067-.258-.141-.416-.347-.605z"
            />
        </svg>
    );
};

export default SpeechBublesIcon;
