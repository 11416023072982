export const JWT_TOKEN_KEY = "JWT_TOKEN_KEY";
export const USER = "USER";
export const USER_DATA = "USER_DATA";

export const HEADER_ID = "header";
export const USER_SITES = "USER_SITES";
export const ACTIVE_SITE = "ACTIVE_SITE";
export const GOOGLE_TOKENS = "GOOGLE_TOKENS";

/**
 * Value for frequencies Select Fields on Settings Page
 * @type {*[]}
 */
export const MEMORY_LIMIT = [ "2M", "4M", "8M", "16M"];

// Value for Select Field on Configuration Tab on Settings Page
export const TIME_LIMIT = [ "10", "30", "60", "0(unlimited)"];

// Value for Select Field on Configuration Tab on Settings Page
export const FREQUENCIES_SELECT_DATA = [ "always", "hourly", "daily", "weekly", "monthly", "yearly", "never"];

/**
 * Value for priority Select Fields on Settings Page
 * @type {*[]}
 */
export const PRIORITIES_SELECT_DATA = [ "0.0", "0.1", "0.2", "0.3", "0.4", "0.5", "0.6", "0.7", "0.8", "0.9", "1.0" ];

export const INITIAL_SETTINGS_VALUE = {
    "sm_license": "",

    //Provider for automatic priority calculation (+?)
    "sm_b_prio_provider": "GoogleSitemapGeneratorPrioByCountProvider",

    //Auto ping Google (+)
    "sm_b_ping": true,

    //Send anonymous stats (+)
    "sm_b_stats": false,

    //Auto ping MSN (+)
    "sm_b_pingmsn": true,

    //Try to gzip the output (+)
    "sm_b_autozip": true,

    //Set Memory Limit (e.g. 16M) (-)
    "sm_b_memory": "",

    //Set time limit in seconds, 0 for unlimited, -1 for disabled (-)
    "sm_b_time": -1,

    //Use default style (+)
    "sm_b_style_default": true,

    //Include a stylesheet in the XML ( (-) There isn't this one in the new version)
    "sm_b_style": "",

    //The base URL of the sitemap (+)
    "sm_b_baseurl": "",

    //Add sitemap location to WordPress' virtual robots.txt file (+)
    "sm_b_robots": true,

    //Include a link to a html version of the sitemap in the XML sitemap (+)
    "sm_b_html": true,

    //List of post / page IDs to exclude
    "sm_b_exclude": [],

    //List of post / page IDs to exclude
    "sm_b_exclude_cats": [],

    //Include homepage ( (-) There isn't this one in the new version)
    "sm_in_home": true,

    //Include posts ( (-) There isn't this one in the new version)
    "sm_in_posts": true,

    //Include post pages (<!--nextpage--> tag)
    "sm_in_posts_sub": false,

    //Include static pages (+)
    "sm_in_pages": true,

    //Include categories (+)
    "sm_in_cats": true,

    //Include archives (+)
    "sm_in_arch": false,

    //Include author pages (+)
    "sm_in_auth": false,

    //Include tag pages (+)
    "sm_in_tags": false,

    //Include additional taxonomies
    "sm_in_tax": [],

    //Include custom post types (+)
    "sm_in_customtypes": [],

    //Include the last modification date (+)
    "sm_in_lastmod": true,

    //Change frequency of the homepage (+)
    "sm_cf_home": "daily",

    //Change frequency of posts (+)
    "sm_cf_posts": "monthly",

    //Change frequency of static pages (+)
    "sm_cf_pages": "weekly",

    //Change frequency of categories (+)
    "sm_cf_cats": "weekly",

    //Change frequency of author pages (+)
    "sm_cf_auth": "weekly",

    //Change frequency of the current archive (this month) (+)
    "sm_cf_arch_curr": "daily",

    //Change frequency of older archives (+)
    "sm_cf_arch_old": "yearly",

    //Change frequency of tags (+)
    "sm_cf_tags": "weekly",

    //Priority of the homepage (+)
    "sm_pr_home": 1.0,

    //Priority of posts (if auto prio is disabled) (+)
    "sm_pr_posts": 0.6,

    //Minimum post Priority, even if autocalc is enabled (+)
    "sm_pr_posts_min": 0.2,

    //Priority of static pages (+)
    "sm_pr_pages": 0.6,

    //Priority of categories (+)
    "sm_pr_cats": 0.3,

    //Priority of archives (+)
    "sm_pr_arch": 0.3,

    //Priority of author pages (+)
    "sm_pr_auth": 0.3,

    //Priority of tags (+)
    "sm_pr_tags": 0.3,

    //Did you donate? Thank you!
    "sm_i_donated": false,

    //And hide the thank you..
    "sm_i_hide_donated": false,

    //The installation date
    "sm_i_install_date": null,

    //Hide the survey note
    "sm_i_hide_survey": false,

    //Hide the note which appears after 30 days
    "sm_i_hide_note": false,

    //Hide the 'works?' message which appears after 15 days
    "sm_i_hide_works": false,

    //Hide the list of donations
    "sm_i_hide_donors": false,

    //Partial hash for GA stats, NOT identifiable!
    "sm_i_hash": null,

    //When was the last ping
    "sm_i_lastping": 0,

    //shows the support feed
    "sm_i_supportfeed": true,

    //Last refresh of support feed
    "sm_i_supportfeed_cache": 0,

    "sm_sitemap_url": null,
};

export const NOT_EDITABLE_USER_ATTRIBUTES = [
    "sub", "identities", "email_verified"
];

export const PATH_LENGTH = 50;

export const buttonsList = [
    {
        name: "Google Overview",
        id: 0
    },
    {
        name: "Bing Overview",
        id: 1
    },
    {
        name: "Site Maps",
        id: 2
    },
    {
        name: "Total Indexed",
        id: 3
    }
];
