import * as actions from "../actions";

const initialState = {
    loading: false,
    message: "",
};

const paymentBraintree = ( state = initialState, action) => {
    switch (action.type) {

        case actions.PAYMENT_BRAINTREE.FAILURE:
            return Object.assign({}, state, {
                loading: false,
                message: action.error,
            });

        case actions.PAYMENT_BRAINTREE.SUCCESS:
            console.log("PAYMENT SUCCESS DETECTED!!");
            return Object.assign({}, state, {
                loading: false,
                message: "",
                paymentCompleted: true
            });

        case actions.PAYMENT_BRAINTREE.REQUEST:
            return Object.assign({}, state, {
                loading: true,
                message: "",
            });

        default:
            return state;
    }
};

export default paymentBraintree;
