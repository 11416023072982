import * as actions from "../actions";

const initialState = {
    loading: false,
    message: "",
};

const subscriptionInfo = ( state = initialState, action ) => {
    switch (action.type) {

        case actions.DISPLAY_SUBSCRIPTION_INFO.FAILURE:
            return Object.assign( {}, state, {
                loading: false,
                message: action.error,
            } );

        case actions.DISPLAY_SUBSCRIPTION_INFO.SUCCESS:
            return Object.assign( {}, state, {
                loading: false,
                message: "",
                subscriptionData: action.data
            } );

        case actions.DISPLAY_SUBSCRIPTION_INFO.REQUEST:
            return Object.assign( {}, state, {
                loading: true,
                message: "",
            } );

        case actions.CLEAR_SUBSCRIPTION_INFO.SUCCESS:
            return Object.assign( {}, state, {
                loading: false,
                message: "",
                subscriptionData: {}
            } );

        default:
            return state;
    }
};

export default subscriptionInfo;
