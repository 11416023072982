import React from "react";
import Messages from "UI/messages";
import withUser from "helpers/withUser";
import ServiceWorkerNotifier from "./../serviceWorkerNotifier";

const dashboardLayout = ({ children }, ) => {
    return (
        <>
            <Messages />
            <ServiceWorkerNotifier />
            {children}
        </>
    );
};

export default withUser(dashboardLayout);
