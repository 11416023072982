//@flow

import React from "react";

//types
import type { FormSwitchType } from "./FormSwitchTypes.js";

// styles
import global from "./../../../assets/scss/global.module.scss";
import styles from "./switch.module.scss";

const FormSwitch = ({ input, meta: { touched, error }, title, text, ...rest } : FormSwitchType) => {
    let errorBlock = null;

    if (touched && error) {
        errorBlock = (
            <div className={styles["error-block"]}>
                <span>{ error }</span>
            </div>
        );
    }

    return (
        <>
            <div className={styles["option-left"]}>
                <div className={`${styles["option-title"]} ${styles["active"]}`}>{title}</div>
                <p className={styles["option-description"]}>{text}</p>
            </div>
            <div className={`${styles["option-right"]} ${global["d-flex"]} ${global["align-items-center"]}`}>
                {input.checked && <span className={`${styles["option-label"]} ${styles["active"]}`}>
                    Active
                </span>}
                {!input.checked && <span className={`${styles["option-label"]} `}>
                    Inactive
                </span>}

                <div className={styles["switch-container"]}>
                    <label>
                        <input {...rest} {...input} className={styles["switch"]} />
                        <div>
                            <div></div>
                        </div>
                    </label>

                </div>
            </div>
            { errorBlock }
        </>
    );
};

export default FormSwitch;
