// @flow
import React from "react";
import asyncComponent from "helpers/asyncComponent";
const SignupUnconfirmed = asyncComponent(() => import("./signup-unconfirmed"));
const CodeVerify = asyncComponent(() => import("./codeVerify"));
const WpVerify = asyncComponent(() => import("./wpVerify"));
const ChooseDomains = asyncComponent(() => import("./chooseDomains"));
import SignUpRoute from "./SignUpRoute";
import { UnauthorizedLayout } from "common/components";

export default [
    <SignUpRoute exact path="/sign-up" layout={UnauthorizedLayout} component={SignupUnconfirmed} key="index" />,
    <SignUpRoute exact path="/sign-up/code-verify" layout={UnauthorizedLayout} component={CodeVerify} key="code-verify" />,
    <SignUpRoute exact path="/sign-up/wp-verify" layout={UnauthorizedLayout} component={WpVerify} key="wp-verify" />,
    <SignUpRoute exact path="/sign-up/choose-domains" layout={UnauthorizedLayout} component={ChooseDomains} key="choose-domains" />,
];
