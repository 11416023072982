//@flow

import React from "react";
import rollingImage from "assets/img/rolling.png";
import global from "../../../assets/scss/global.module.scss";
import styles from "./dataIsLoading.module.scss";

const DataIsLoading = () => {
    return (
        <div className={`${styles["loading-wrapper"]} ${global["d-flex"]} ${global["flex-column"]} ${global["align-items-center"]}`}>
            <div className={styles.rotating}>
                <img src={rollingImage} alt="rolling"/>
            </div>
            <h3 className={`${global.title} ${styles.title}`}>Your data is currently loading</h3>
            <p>It might take from up to 24 hours for the graphs to show. </p>
        </div>
    );
};

export default DataIsLoading;
