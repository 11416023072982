import React from "react";
import { googleURL } from "helpers/constants";

const GoogleButton = () => (
    <a
        href={googleURL}
    >
        Google
    </a>
);

export default GoogleButton;
