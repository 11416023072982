//@flow

import React from "react";
import global from "../../../assets/scss/global.module.scss";
import styles from "./settingsHeading.module.scss";

import type { SettingsHeadingType } from "./SettingsHeadingType";

const SettingsHeading = (props: SettingsHeadingType) => {
    return (
        <div className={`${global["d-flex"]} ${global["justify-content-between"]}`}>
            <h5 className={`${global.title} ${styles.title}`}>{props.title}</h5>
            {props.learnMoreLink && <a href="#">Learn More</a>}
        </div>
    );
};

export default SettingsHeading;
