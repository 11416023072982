import * as actions from "actions/user";

const initialState = {
    wpToken: "",
    user: null,
    userData: null,
    sites: [],
    activeSite: null,
    isAuthenticated: false
};

/**
 * @author Roman Yephimov <ry@piogroup.net>
 * @desc Sign Up Reducer
 * @param {Object} state - The current state
 * @param {Object} action - The action data
 */
const user = (state = initialState, action) => {
    switch (action.type) {

        case actions.SET_USER:
            return Object.assign({}, state, {
                user: action.data,
                userData: action.data,
            });

        case actions.SET_ISAUTHENTICATED:
            return Object.assign({}, state, {
                isAuthenticated: action.data,
            });

        case actions.SET_USER_DATA:
            return Object.assign({}, state, {
                userData: action.data,
            });

        case actions.SET_TOKEN:
            return Object.assign({}, state, {
                wpToken: action.data,
            });

        case actions.SET_DOMAINS_DATA:
            return Object.assign({}, state, {
                sites: action.data,
                activeSite: action.data[0],
            });

        case actions.SET_ACTIVE_DOMAIN:
            return Object.assign({}, state, {
                activeSite: action.data,
            });

        case actions.SET_USER_DOMAINS:
            return Object.assign({}, state, {
                sites: action.data,
            });

        case actions.UPDATE_ACTIVE_DOMAIN:
            return Object.assign({}, state, {
                activeSite: action.data,
                sites: state.sites.map(siteItem => siteItem.domain === action.data.domain ? action.data : siteItem)
            });

        case actions.LOGOUT:
            return Object.assign({}, state, {
                activeSite: "",
                sites: [],
                userData: null,
                wpToken: "",
                user: null,
            });
        default:
            return state;
    }
};

export default user;
