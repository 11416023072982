// @flow

import { connect } from "react-redux";
import Component from "./TopPanel";
import * as actions from "actions/user";

const mapStateToProps = ({ user }) => ({ user });

const mapDispatchToProps = dispatch => {
    return {
        changeActiveDomain: data => {
            dispatch(actions.setActiveDomain(data));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Component);
