import React from "react";
import { HashRouter, Switch, Route, BrowserRouter } from "react-router-dom";
import asyncComponent from "helpers/asyncComponent";
const Settings = asyncComponent(() => import("components/settings"));
const Dashboard = asyncComponent(() => import("components/dashboard"));
const ForgotPass = asyncComponent(() => import("components/forgotPassword"));
import Signup from "../components/signup/routes";
const SignIn = asyncComponent(() => import("components/signIn"));
const Payment = asyncComponent(() => import("components/payment"));
const PaymentBraintree = asyncComponent(() => import("components/paymentBraintree"));
const PaymentUpdateMethod = asyncComponent(() => import("components/paymentUpdateMethod"));
const SubscriptionInfo = asyncComponent(() => import("components/subscriptionInfo"));
const MySites = asyncComponent(() => import("components/mySites"));
const PaymentResult = asyncComponent(() => import("components/paymentResult"));
const PricingPlans = asyncComponent(() => import("components/pricingPlans"));
import TestComponent from "components/testsso";
import AddDomains from "components/addDomains/routes";
import ProtectedRoute from "./protectedRoute";
import NotFoundRoute from "./notFoundRoute";
import { Layout, dashboardLayout } from "common/components";
import AppRoute from "./AppRoute";
/**
 * @author Roman Yephimov <ry@piogroup.net>
 * @desc Hash router with all routers
 *
 * @param {Object} children - Children components
 * @param {Object} childProps - Children props
 */

const Router = () => {
    return (
        <BrowserRouter>
            <Switch>
                {Signup}
                {AddDomains}
                <AppRoute exact path="/" layout={dashboardLayout} component={Dashboard} />
                <AppRoute exact path="/forgot-password" layout={Layout} component={ForgotPass} />
                <AppRoute exact path="/sign-in" layout={Layout} component={SignIn} />
                <AppRoute exact path="/pricing-plans" layout={Layout} component={PricingPlans} />
                <AppRoute exact path="/payment" layout={Layout} component={Payment} />
                <ProtectedRoute exact path="/settings" layout={Layout} component={Settings} />
                <ProtectedRoute exact path="/payment" layout={Layout} component={Payment} />
                <AppRoute exact path="/payment-braintree" layout={Layout} component={PaymentBraintree} />
                <AppRoute exact path="/payment-update-method" layout={Layout} component={PaymentUpdateMethod} />
                <AppRoute exact path="/subscription-info" layout={Layout} component={SubscriptionInfo} />
                <AppRoute exact path="/my-sites" layout={Layout} component={MySites} />
                <ProtectedRoute exact path="/payment-result" layout={Layout} component={PaymentResult} />
                <ProtectedRoute exact path="/add-domains" layout={Layout} component={AddDomains} />
                <Route exact path="/sso" component={TestComponent} />
                <NotFoundRoute layout={Layout} component={() => <div>not found</div>} />
            </Switch>
        </BrowserRouter>
    );
};

export default Router;

