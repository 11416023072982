import dotenv from "dotenv";

dotenv.config();

/**
 * @author Alex Malyi <am@piogroup.net>
 * @desc Function for creating actions with three main cases
 * @param {String} base - The base name of action
 * @param {Array} optional - Array with optional action cases
 */

const {
    REACT_APP_COGNITO_DOMAIN,
    REACT_APP_SIGN_IN_REDIRECT_URI,
    REACT_APP_COGNITO_APP_CLIENT_ID,
} = process.env;

export function createRequestTypes(base, optional) {
    for (let index in optional) {
        optional[index] = `${base}_${index}`;
    }

    return {
        ...optional,
        REQUEST: `${base}_REQUEST`,
        SUCCESS: `${base}_SUCCESS`,
        FAILURE: `${base}_FAILURE`,
    };
}

export const code = {code:''}

export const facebookURL = `${REACT_APP_COGNITO_DOMAIN}/oauth2/authorize?redirect_uri=${REACT_APP_SIGN_IN_REDIRECT_URI}&response_type=token&client_id=${REACT_APP_COGNITO_APP_CLIENT_ID}&identity_provider=Facebook&state=STATE&
scope=aws.cognito.signin.user.admin+openid+profile`;

// export const googleURL = `${REACT_APP_COGNITO_DOMAIN}/oauth2/authorize?redirect_uri=${REACT_APP_SIGN_IN_REDIRECT_URI}&response_type=token&client_id=${REACT_APP_COGNITO_APP_CLIENT_ID}&identity_provider=Google&scope=aws.cognito.signin.user.admin+openid+profile`;


export const googleURL = `https://${REACT_APP_COGNITO_DOMAIN}/oauth2/authorize?redirect_uri=${REACT_APP_SIGN_IN_REDIRECT_URI}&access_type=offline&include_granted_scopes=true&response_type=code&client_id=${REACT_APP_COGNITO_APP_CLIENT_ID}&identity_provider=Google&scope=aws.cognito.signin.user.admin+openid+profile`;

export const accessToken = `https://www.googleapis.com/oauth2/v4/token?scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fwebmasters%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fwebmasters.readonly%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Findexing&grant_type=authorization_code&client_id=${process.env.REACT_APP_GOOGLE_CLIENT_ID}&client_secret=${process.env.REACT_APP_GOOGLE_CLIENT_SECRET}&redirect_uri=http://localhost:3001&code=`
