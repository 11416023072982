//@flow

import React from "react";
import rollingImage from "assets/img/rolling.png";

//styles
import global from "../../../assets/scss/global.module.scss";
import styles from "./loaderElement.module.scss";

const LoaderElement = () => {
    return (
        <div className={`${styles["loading-wrapper"]} ${global["d-flex"]} ${global["flex-column"]} ${global["align-items-center"]}`}>
            <div className={styles.rotating}>
                <img src={rollingImage} alt="rolling"/>
            </div>
        </div>
    );
};

export default LoaderElement;
