import * as actions from "../actions";

const initialState = {
    loading: false,
    message: "",
};

/**
 * @author Alex Malyi <am@piogroup.net>
 * @desc Auth reducer
 * @param {Object} state - The current state
 * @param {Object} action - The action data
 */
const dashboard = (state = initialState, action) => {
    switch (action.type) {

        case actions.GENERATE_SITE_MAP.FAILURE:
            return Object.assign({}, state, {
                loading: false,
                message: action.error.message,
            });

        case actions.GENERATE_SITE_MAP.SUCCESS:
            return Object.assign({}, state, {
                loading: false,
                message: "Site Map successfully generated!",
            });

        case actions.GENERATE_SITE_MAP.REQUEST:
            return Object.assign({}, state, {
                loading: true,
                message: "",
            });

        default:
            return state;
    }
};

export default dashboard;
