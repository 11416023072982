// @flow
import React from "react";
import global from "../../../assets/scss/global.module.scss";
import styles from "./buttonPrimary.module.scss";

import type { ButtonPrimaryTypes } from "./buttonPrimaryTypes";

const ButtonPrimary = (props: ButtonPrimaryTypes) => {
    const { buttonSize, btnCenter, buttonText, disabled, withIcon = false, action = () => {} } = props;
    return (
        <button
            className={`${global["d-flex"]} ${global["with-shadow"]} ${styles.button} ${styles[buttonSize]} ${styles[btnCenter]} ${styles[withIcon ? "with-icon" : null]}`}
            type="button"
            disabled={disabled}
            onClick={action}
        >
            {withIcon}
            <span>{buttonText}</span>
        </button>
    );
};

ButtonPrimary.defaultProps = {btnCenter:""};
export default ButtonPrimary;
