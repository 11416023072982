//@flow

import React from "react";
import global from "./../../../assets/scss/global.module.scss";
import styles from "./heading.module.scss";

import type { HeadingType } from "./HeadingType.js";

const Heading = ({ title, subtitle, align, icon }:HeadingType) => {
    return(
        <div className={`${styles["heading"]} ${styles[align]}`}>
            {icon}
            <h1 className={`${global.title} ${styles.title}`}>
                {title}
            </h1>
            <p className={styles.subtitle}>
                {subtitle}
            </p>
        </div>
    );
};

export default Heading;
