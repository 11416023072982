//@flow
import React from "react";
import type { ButtonSignUpComponentTypes } from "./buttonSignUpComponentTypes";
import global from "../../../assets/scss/global.module.scss";
import styles from "./buttonSignUp.module.scss";

const ButtonSignUp = (props:ButtonSignUpComponentTypes) => {
    return (
        <div onClick={()=>props.onClickBtn(props.type)} className={`${global["d-flex"]} ${styles.button} ${styles[props.color]} `}>
            <span className={`${global["d-flex"]} ${styles["icon-wrapper"]}`}>
                {props.icon}
            </span>
            {props.text}
        </div>
    );
};

export default ButtonSignUp;
