import { createRequestTypes } from "../../helpers/constants";

export const DISPLAY_SUBSCRIPTION_INFO = createRequestTypes("DISPLAY_SUBSCRIPTION_INFO");
export const CLEAR_SUBSCRIPTION_INFO = createRequestTypes("CLEAR_SUBSCRIPTION_INFO");

export const displaySubscriptionInfo = {
    request: request => ({ type: DISPLAY_SUBSCRIPTION_INFO.REQUEST, request }),
    success: data => ({ type: DISPLAY_SUBSCRIPTION_INFO.SUCCESS, data }),
    error:   error => ({ type: DISPLAY_SUBSCRIPTION_INFO.FAILURE, error })
};

export const clearSubscriptionInfo = {
    success: () => ({ type: CLEAR_SUBSCRIPTION_INFO.SUCCESS }),
};
