//flow

import React from "react";
import {
    BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from "recharts";

const BarChartComponent = (props: AreaChartPropTypes) => {
    const { chartData } = props;
    return (
        <ResponsiveContainer aspect={3}>
            <BarChart
                width={950}
                height={273}
                data={chartData}
                margin={{ top: 15, right: 15, left: 15, bottom: 15 }}
                barCategoryGap="20%"
            >
                <XAxis dataKey="name" padding={{ left: 30, right: 30 }} />
                <YAxis />
                <CartesianGrid strokeDasharray="5 8" vertical={false} />
                <Tooltip />
                <Legend />
                <Bar dataKey="Submited" fill="#BCEDE5" />
                <Bar dataKey="Indexed" fill="#36aca3" />
            </BarChart>
        </ResponsiveContainer>
    );
};

export default BarChartComponent;
