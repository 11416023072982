//@flow

import React from "react";
import { CheckIcon, ExclamationIcon, ErrorIcon } from "../../../assets/icons";

import global from "../../../assets/scss/global.module.scss";
import styles from "./toastMessage.module.scss";

import type { ToastMessageType } from "./ToastMessageType";

const notificationState = {
    success: <CheckIcon />,
    warning: <ExclamationIcon />,
    error: <ErrorIcon />,
};

const ToastMessage = (props: ToastMessageType) => {
    const { messageConfig, text } = props;
    return (
        <div className={`${styles["toast-message-wrapper"]} ${styles[messageConfig]} ${global["d-flex"]} ${global["align-items-center"]}`}>
            <div className={styles["icon"]}>
                {notificationState[messageConfig]}
            </div>
            <p className={styles["message"]}>
                {text}
            </p>
        </div>
    );
};

export default ToastMessage;
