import { put, take, fork, call } from "redux-saga/effects";
import { API } from "aws-amplify";
import * as actions from "./actions";
import * as userActions from "actions/user";
import dotenv from "dotenv";
import { premiumResponse } from "../../config/offlineData";
dotenv.load();

const {
    REACT_APP_API_GATEWAY_URL
} = process.env;

function* watchDisplayMySitesData() {
    while (true) {
        const { request: { domain, action } } = yield take(actions.DISPLAY_MY_SITES_DATA.REQUEST);
        console.log("Got display request");
        try {

            let response = {};
            console.log("About to call the lambda backend for subscription info");
            let jsonData = {};

            if(process.env.REACT_APP_MOCK === 'true'){
                response  = ["http://testdomain12322.com", "http://anotherdomain.com"];
                jsonData = response;

            } else {

                // response = yield API.post("auctollo", "/payment/get-subscription-info", reqBody);
                let body =JSON.stringify({domain});
                console.log("body:");
                console.log(body);
                // response = yield fetch(`${REACT_APP_API_GATEWAY_URL}/payment/subscriptionInfo`, {method: "POST", body});
                const responseObject = yield response.json();
                jsonData = JSON.parse(responseObject.body).data;
            }

            yield put(actions.displayMySitesData.success(jsonData));
        } catch (e) {
            console.log("error in watchDisplayMySitesData");
            console.log(e);
            yield put(actions.displayMySitesData.error(e.message));
        }
    }
}

export default [
    fork(watchDisplayMySitesData),
];
