// @flow

/**
 * @author Roman <roman.slyusar@piogroup.net>
 */

import type { ElementType } from "react";

export type SignUpRoutePropsType = {
    component: ElementType,
    layout: ElementType,
};

import React from "react";
import { Route } from "react-router-dom";
import SocialButtons from "./social-buttons";
import { Heading } from "common/components";
import global from "./../../assets/scss/global.module.scss";
import { Auth } from "aws-amplify";

import { buttonsConfig } from "./buttonsConfigData";

const onClickSocialBtn = (type: string )=> {

    console.log('type', type)
      if(type === 'google') {
          Auth.federatedSignIn({provider: 'Google'})
      }
  }

const SignUpRoute = ({ component: Component, layout: Layout, ...rest }: SignUpRoutePropsType) => {
    return (
        <Route
            {...rest} render={props => (
                <Layout>
                    <section className={global["section"]}>
                        <Heading
                            title="Get started with the Premium plan."
                            subtitle="$35/year. No contracts, cancel anytime."
                            align="center"
                        />
                        <div className={global["section-body"]}>
                            <div className={`${global["bg-white"]} ${global["with-shadow"]} ${global["sign-up-form"]}`}>
                                <SocialButtons onClickBtn={type=>onClickSocialBtn(type)}  buttonsConfig={buttonsConfig}/>
                                <Component {...props} />
                            </div>
                        </div>
                    </section>
                </Layout>
            )}
        />
    );
};

export default SignUpRoute;
