import { combineReducers } from "redux";
import { routerReducer } from "react-router-redux";
import { reducer as formReducer } from "redux-form";
import auth from "components/dashboard/reducers";
import signUpReducers from "components/signup/reducers";
import payment from "components/payment/reducers";
import paymentBraintree from "components/paymentBraintree/reducers";
import subscriptionInfo from "components/subscriptionInfo/reducers";
import mySitesData from "components/mySites/reducers";
import signInReducers from "components/signIn/reducers";
import settings from "components/settings/reducers";
import messages from "./messages";
import user from "./user";
import serviceWorker from "./serviceWorker";
import appSettings from "./appSettings";

/**
 * @author Alex Malyi <am@piogroup.net>
 * @desc Main reducer which include all app's reducers
 */
const reducers = Object.assign(
    {
        form: formReducer,
        router: routerReducer,
    },
    auth,
    { user },
    { messages },
    signUpReducers,
    signInReducers,
    settings,
    payment,
    paymentBraintree,
    subscriptionInfo,
    mySitesData,
    { appSettings },
    { serviceWorker }
);

export default combineReducers(reducers);
