// @flow

import { sitemapStatusHeaderConfig } from "components/dashboard/mockedData";
import { prepereBigNumber } from "helpers/functions";

type rowItem = {
    submitted: number,
    indexed: number,
    contents: string,
}

export const getSitemapsTotalSum = (data: Array<rowItem>, key: any) => {
    return data.reduce((prev, cur) => prev + parseInt(cur.contents[0][key]), 0);
};

export const preparesubmittedSitemaps = (data: Array<rowItem>) => {
    let count = getSitemapsTotalSum(data, "submitted");
    return prepereBigNumber(count);
};

export const prepareindexedSitemaps = (data: Array<rowItem>) => {
    let count = getSitemapsTotalSum(data, "indexed");
    return prepereBigNumber(count);
};

export const prepareHeaderSitemapData = (data: Array<rowItem>) => {
    const prepare = { preparesubmittedSitemaps, prepareindexedSitemaps };
    return sitemapStatusHeaderConfig.map(item => {
        item.value = prepare[`prepare${item.type}`](data);
        return item;
    });
};
