// @flow
import React from "react";
import { Auth, API } from "aws-amplify";
import { withRouter, History } from "react-router-dom";
import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";
import { connect } from "react-redux";
import auth from "./authHelper";
import * as Actions from "actions/user";
import { get } from "lodash";
import { awsAuth } from "../config/offlineData";

export type AuthHelperTpye = *; // TODO add type

export type UserType = * // TODO add type

export type WithUserStateType = {
    isAuthenticated: boolean,
}

export type WithUserPropsType = {
    activeSite: {
        domain: String,
        isExist: boolean
    },
    setUserData: <UserType>(param: UserType | null) => UserType,
    setIsauthenticated: (setIsauthenticated: boolean) => void,
    setDomainsData: (sites: Array<DomainType>) => void,
    isAuthenticated: boolean,
    history: History,
    logout: () => void,
}

export type DomainType = {
    domain: string,
    key: string,
    userId: string | number
}

/**
 *
 * @author Roman Slyusar <roman.slyusar@piogroup.net>
 * @param Component
 * @returns HOC with user data
 */
export default function withUser<Props: WithUserPropsType>(Component: *): React.Component<*> {

    class WithUser extends React.Component<Props, WithUserStateType> {
        auth: AuthHelperTpye;
        constructor(props){
            super(props);

            this.auth = auth;
            this.auth.userhandler = {
                onSuccess: function(result) {
                    () => this.setIsAuthenticated(result);
                },
                onFailure: function() {
                    // TODO add errorHandler
                }
            };
        }

        async componentDidMount() {
            await this.authFromURL();
            await this.isUserAuthenticated();
        }
        /**
         * get UserInfo from URL, sync with Cognito and set to localstorage
         *
         * @author Roman Slyusar <roman.slyusar@piogroup.net>
         */
        authFromURL = async () => {
            try {
                const curUrl = window.location.href;
                const re = /#\//;
                const stringToReplace = curUrl.replace(re, "#");
                if (stringToReplace.indexOf("access_token") !== -1) {
                    auth.parseCognitoWebResponse(stringToReplace);
                    await this.getCurrentUser();
                }
            } catch (e) {
                // TODO add errorHandler
            }
        }

        /**
         * set isAuthenticated
         * for now let's use this as App property
         * @author Roman Slyusar <roman.slyusar@piogroup.net>
         */
        setIsAuthenticated = isAuthenticated => {
            this.props.setIsauthenticated(isAuthenticated);
        }

        /**
         * is user isAuthenticated in the application NOT in cognito
         * user can be signed in in cognito but not in app e.g. facebook user
         * @author Roman Slyusar <roman.slyusar@piogroup.net>
         */
        isUserAuthenticated = async () => {
            let email;
            try {
                const { attributes } = await this.getCurrentUserAttributes() || {};
                const { isAuthenticated, activeSite } = this.props;
                if (isAuthenticated && !activeSite) {
                    const { sites } = await API.get("auctollo", "/site/get-sites");
                    sites && sites.lenght > 0 &&this.props.setDomainsData(sites);
                }
                email = attributes && attributes.email;
                email && this.props.activeSite && this.setIsAuthenticated(true);
            } catch(e) {
                this.setIsAuthenticated(false);
                // TODO add custom error handler
                return;
            }
        }

        /**
         * is user isAuthenticated in cognito
         * DO NOT use directly in components
         * @author Roman Slyusar <roman.slyusar@piogroup.net>
         */
        isCognitAuthenticated = () => this.auth.isUserSignedIn()

        /**
         * get UserInfo from localstorage and sync with Cognito
         *
         * @author Roman Slyusar <roman.slyusar@piogroup.net>
         */
         getCurrentUser = async () => {
             const cognitoUser = await Auth.currentUserPoolUser();
             if (!cognitoUser) return null;
             const { setUserData } = this.props;
             return cognitoUser.getSession(function(err, session) {
                 if (err) {
                     // TODO add custom error handler
                     alert(err);
                     return;
                 }
                 return session.isValid()
                     ? setUserData(cognitoUser)
                     : null;
             });
         }

        getCurrentUserAttributes = async () => {
            if(process.env.REACT_APP_MOCK === 'true'){
                return await awsAuth;
            } else {
                return await Auth.currentUserInfo();
            }
         };

         /**
         * UPDATE user attributes e.g. email
         * @param attributeList
         * const attributeList = [
         * {
                Name : "email",
                Value : "email@mydomain.com"
            }
         * ]
         * @author Roman Slyusar <roman.slyusar@piogroup.net>
         */
         updateUserAttributes = async attributeList => {
             try {
                 const cognitoUser = await this.getCurrentUser();

                 const cognitoAttributeList = attributeList.map(item => {
                     return new AmazonCognitoIdentity.CognitoUserAttribute(item);
                 });

                 cognitoUser && cognitoUser.updateAttributes(cognitoAttributeList, async (err, result) => {
                     if (err) {
                         alert(err);
                         return;
                     }
                     cognitoUser.clearCachedUserData();
                     await this.getCurrentUser();
                     return result;
                 });
             } catch(err) {
                 // TODO add custom error handler
                 alert(err);
                 return;
             }
         }

         handleLogout = async () => {
             const { setUserData, history, logout, setDomainsData } = this.props;
             setUserData(null);
             setDomainsData([]);
             this.setIsAuthenticated(false);
             logout();
             try {
                 const user = await this.getCurrentUser();
                 user && user.signOut();
             } catch(e) {
                 // TODO add custom error handler
                 alert(e);
             }
             history.push("/sign-in");
         }

         render() {
             const { isAuthenticated, activeSite } = this.props;

             return (

                 <Component
                     activeSite={activeSite}
                     getCurrentUser={this.getCurrentUser}
                     handleLogout={this.handleLogout}
                     isAuthenticated={isAuthenticated}
                     updateUserAttributes={this.updateUserAttributes}
                     isCognitAuthenticated={this.isCognitAuthenticated}
                     {...this.props}
                 />

             );
         }
    }

    const mapStateToProps = state => ({
        isAuthenticated: state.user.isAuthenticated,
        activeSite: get(state, "user.activeSite", null),
        subscriptionType: get(state, "user.activeSite.type", "community")
    });

    const mapDispatchToProps = dispatch => {
        return {
            setUserData: data => {
                dispatch(Actions.setUserData(data));
                return data;
            },
            setIsauthenticated: data => {
                dispatch(Actions.setIsauthenticated(data));
            },
            logout: () => {
                dispatch(Actions.logout());
            },
            setDomainsData: domains => {
                dispatch(Actions.setDomainsData(domains));
            }
        };
    };

    return withRouter(
        connect(mapStateToProps, mapDispatchToProps)(WithUser)
    );
}
