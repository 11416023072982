import auth from "./auth";
import dashboard from "./dashboard";
import googleWebmasterSection from "./googleWebmasterSection";
import sitemapStatusSection from "./sitemapStatusSection";

export default {
    auth,
    dashboard,
    googleWebmasterSection,
    sitemapStatusSection,
};
