// @flow
import React from "react";
import global from "../../../assets/scss/global.module.scss";
import styles from "./buttonOutline.module.scss";

import type { ButtonOutlineType } from "./buttonOutlineType";

const ButtonOutline = (props: ButtonOutlineType) => {
    const { buttonText, action } = props;
    return (
        <button
            className={`${global["d-flex"]} ${styles.button}`}
            type="button"
            onClick={action}
        >
            {buttonText}
        </button>
    );
};

export default ButtonOutline;
