import * as actions from "../actions";

const initialState = {
    loading: false,
    message: "",
    signUpError: "",
};

/**
 * @author Roman Yephimov <ry@piogroup.net>
 * @desc Sign Up Reducer
 * @param {Object} state - The current state
 * @param {Object} action - The action data
 */
const signUp = (state = initialState, action) => {
    switch (action.type) {

        case actions.SIGN_UP.FAILURE:
            return Object.assign({}, state, {
                loading: false,
                signUpError: action.error,
            });

        case actions.SIGN_UP.SUCCESS:
            return Object.assign({}, state, {
                loading: false,
                signUpError: "",
            });

        case actions.SIGN_UP.REQUEST:
            return Object.assign({}, state, {
                loading: true,
                message: "",
            });

        default:
            return state;
    }
};

export default signUp;
