import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import "assets/css/index.css";
import { store, persistor } from "./store";
import * as serviceWorker from "common/serviceWorker/serviceWorker";
import { PersistGate } from "redux-persist/integration/react";
import API from "@aws-amplify/api";
import Amplify, { Auth } from "aws-amplify";
import dotenv from "dotenv";
import AWS from "aws-sdk";
import { updateServiceworker } from "actions/serviceWorker";
import App from "./App";
import "./assets/scss/common.scss";
dotenv.config();

const onUpdate = () => {
    store.dispatch(updateServiceworker());
};

const getToken = async () => {
    try {
        return (await Auth.currentSession()).idToken.jwtToken;
    } catch {
        return null;

    }
};

const setCustomHeaders = async () => {
    const token = await getToken();
    let headers = {};
    if (token) {
        headers = {
            Authorization:  await getToken()
        };
    }
    return headers;
};

const {
    REACT_APP_REGION,
    REACT_APP_S3_BUCKET,
    REACT_APP_API_GATEWAY_URL,
    REACT_APP_COGNITO_USER_POOL_ID,
    REACT_APP_COGNITO_APP_CLIENT_ID,
    REACT_APP_COGNITO_IDENTITY_POOL_ID,
    REACT_APP_ACCESS_KEY_ID,
    REACT_APP_SECRET_ACCESS_KEY,
    REACT_APP_COGNITO_DOMAIN,
    REACT_APP_SIGN_IN_REDIRECT_URI
} = process.env;

/**
 * @author Roman Yephimov <ry@piogroup.net>
 * @desc Global config for AWS Amplify for work with Amazon Services
 */
Amplify.configure({
    Auth: {
        mandatorySignIn: true,
        region: REACT_APP_REGION,
        userPoolId: REACT_APP_COGNITO_USER_POOL_ID,
        userPoolWebClientId: REACT_APP_COGNITO_APP_CLIENT_ID,
        identityPoolId: REACT_APP_COGNITO_IDENTITY_POOL_ID,
        oauth: {
            domain: REACT_APP_COGNITO_DOMAIN,
            scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
            redirectSignIn: 'http://localhost:3001/sign-in',
            redirectSignOut: 'http://localhost:3001/sign-in',
            responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
        }
    },
    Storage: {
        region: REACT_APP_REGION,
        bucket: REACT_APP_S3_BUCKET,
        identityPoolId: REACT_APP_COGNITO_IDENTITY_POOL_ID,
    },
});
window.LOG_LEVEL = 'DEBUG';
API.configure(
    {
        endpoints: [
            {
                name: "auctollo",
                endpoint: REACT_APP_API_GATEWAY_URL,
                region: REACT_APP_REGION,
                custom_header: async () => setCustomHeaders()
            },
            {
                name: "cognito",
                endpoint: 'https://'+REACT_APP_COGNITO_DOMAIN,
            },
        ],
    }
);

AWS.config.update({
    accessKeyId: REACT_APP_ACCESS_KEY_ID,
    secretAccessKey: REACT_APP_SECRET_ACCESS_KEY,
    region: REACT_APP_REGION,
});
ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <div className="container">
                <App />
            </div>
        </PersistGate>
    </Provider>,
    document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({ onUpdate });
