import { createRequestTypes } from "helpers/constants";

export const SET_USER_DATA = "SET_USER_DATA";
export const SET_USER = "SET_USER";
export const SET_TOKEN = "SET_TOKEN";
export const SET_DOMAINS_DATA = "SET_DOMAINS_DATA";
export const SET_USER_DOMAINS = "SET_USER_DOMAINS";
export const SET_ACTIVE_DOMAIN = "SET_ACTIVE_DOMAIN";
export const UPDATE_ACTIVE_DOMAIN = "UPDATE_ACTIVE_DOMAIN";
export const LOGOUT = "LOGOUT";
export const GET_TOKEN = createRequestTypes("GET_TOKEN");

export const SET_ISAUTHENTICATED = "SET_ISAUTHENTICATED";

/**
 * @author Roman Yephimov <ry@piogroup.net>
 * @desc Set user to storage action
 */
export const setUser = data => ({ type: SET_USER, data });

/**
 * @author Roman Yephimov <ry@piogroup.net>
 * @desc Set user data to storage action
 */
export const setUserData = data => ({ type: SET_USER_DATA, data });

/**
 * @author Roman Yephimov <ry@piogroup.net>
 * @desc Set token to storage action
 */
export const setToken = data => ({ type: SET_TOKEN, data });

/**
 * @author Roman Yephimov <ry@piogroup.net>
 * @desc Set domains data
 */
export const setDomainsData = data => ({ type: SET_DOMAINS_DATA, data });

/**
 * @author Roman Yephimov <ry@piogroup.net>
 * @desc Set domains data
 */
export const setUserDomains = data => ({ type: SET_USER_DOMAINS, data });

/**
 * @author Roman Yephimov <ry@piogroup.net>
 * @desc Set active domain data
 */
export const setActiveDomain = data => ({ type: SET_ACTIVE_DOMAIN, data });

/**
 * @author Roman Yephimov <ry@piogroup.net>
 * @desc Update active domain
 */
export const updateActiveDomain = data => ({ type: UPDATE_ACTIVE_DOMAIN, data });

/**
 * @author Roman Yephimov <ry@piogroup.net>
 * @desc Set active domain data
 */
export const logout = () => ({ type: LOGOUT });

export const setIsauthenticated = data => ({ type: SET_ISAUTHENTICATED, data });
