//@flow

import React from "react";
import { Heading, ButtonPrimary } from "../../../common/components";
import { LockOpenedIcon, EyeCrossedIcon } from "../../../assets/icons";

import global from "../../../assets/scss/global.module.scss";
import styles from "./seeMore.module.scss";

import type { SeeMoreTypes } from "./SeeMoreTypes";

const SeeMore = (props: SeeMoreTypes) => {
    const { handleGetPremium } = props;
    return (
        <div className={`${styles["see-more-wrapper"]} ${global["d-flex"]} ${global["flex-column"]} ${global["align-items-center"]}`}>
            <Heading
                align="center"
                icon={<EyeCrossedIcon/>}
                title="Want to see more?"
                subtitle="This content is only available for Premium users." />
            <ButtonPrimary
                withIcon={<LockOpenedIcon/>}
                buttonSize="medium"
                buttonText="Get Premium"
                action={handleGetPremium}
            />
        </div>
    );
};

export default SeeMore;
