import React from "react";
import { Route, Redirect } from "react-router-dom";
import withUser from "helpers/withUser";

const ProtectedRoute = ({ component: Component, layout: Layout, ...rest }) => {
    return (
        <Route
            {...rest} render={() => {
                return (
                    rest.isAuthenticated
                        ? <Layout><Component {...rest} /></Layout>
                        : <Redirect
                            to="/sign-in"
                        />
                );

            }}
        />
    );
};

export default withUser(ProtectedRoute);
