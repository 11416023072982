import * as actions from "../actions";

const initialState = {
    loading: false,
    message: null,
    data: {},
    updateRobotsMessage: null,
    updateRobotsLoader: false,
    categories: [],
    postTypes: [],
};

/**
 * @author Alex Malyi <am@piogroup.net>
 * @desc Settings Reducer
 * @param {Object} state - The current state
 * @param {Object} action - The action data
 */
const settings = (state = initialState, action) => {
    switch (action.type) {
        case actions.GET_SETTINGS.FAILURE:
            return Object.assign({}, state, {
                loading: false,
                message: action.error.message,
            });

        case actions.GET_SETTINGS.SUCCESS: {
            const categoriesChecked = {};
            const postTypesChecked ={};
            const { data, categories, postTypes } = action.response;
            const { sm_b_exclude_cats, sm_in_customtypes } = data;
            sm_b_exclude_cats && sm_b_exclude_cats.length > 0 && sm_b_exclude_cats.forEach(item => {
                categories.forEach(elem => {
                    if ( elem.ID === item ) {
                        categoriesChecked[`ex_cat_${item}`] = true;
                    }
                });
            });
            sm_in_customtypes && sm_in_customtypes.length > 0 && sm_in_customtypes.forEach(item => {
                postTypes.forEach(elem => {
                    if ( elem.name === item ) {
                        postTypesChecked[`posttype_${item}`] = true;
                    }
                });
            });

            const newData = {
                ...data,
                ...categoriesChecked,
                ...postTypesChecked
            };

            return Object.assign({}, state, {
                loading: false,
                message: "Settings successfully loaded!",
                data: newData,
                categories: categories,
                postTypes: postTypes,
            });
        }

        case actions.GET_SETTINGS.REQUEST:
            return Object.assign({}, state, {
                loading: true,
                message: "",
                data: {},
            });

        case actions.GET_SETTINGS.INIT_DEFAULT_VALUE:
            return Object.assign({}, state, {
                loading: false,
                message: "",
                data: action.data,
            });

        case actions.UPDATE_ROBOTS.REQUEST:
            return Object.assign({}, state, {
                updateRobotsMessage: "",
                updateRobotsLoader: true,
            });

        case actions.UPDATE_ROBOTS.SUCCESS:
            return Object.assign({}, state, {
                updateRobotsMessage: action.response.message,
                updateRobotsLoader: false,
            });

        case actions.UPDATE_ROBOTS.FAILURE:
            return Object.assign({}, state, {
                updateRobotsMessage: action.error.message,
                updateRobotsLoader: false,
            });

        default:
            return state;
    }
};

export default settings;
