// @flow
import React from "react";

// styles
import styles from "./serviceWorker.module.scss";

export type ServiceWorkerNotifierType = {
    serviceWorkerUpdated: boolean
}

const ServiceWorkerNotifier = (
    { serviceWorkerUpdated }: ServiceWorkerNotifierType
) => {
    return serviceWorkerUpdated ? (
        <div className={styles["modal-wrapper"]}>
            <div className={styles["modal-container"]}>
                <p>There is a newer version of Google XML Sitemaps. Please reload the page to get the latest version.</p>
                <a
                    href="javascript:window.location.reload(true)"
                    className={` ${styles["get-started-link"]}`}>
                    Reload
                </a>
            </div>
        </div>
    )
        : null;
};

export default ServiceWorkerNotifier;
