import dotenv from "dotenv";
import { CognitoAuth } from "amazon-cognito-auth-js";
dotenv.config();

const {
    REACT_APP_COGNITO_APP_CLIENT_ID,
    REACT_APP_COGNITO_IDENTITY_POOL_ID,
    REACT_APP_WEB_DOMAIN,
    REACT_APP_SIGN_IN_REDIRECT_URI,
} = process.env;

const authData = {
    ClientId : REACT_APP_COGNITO_APP_CLIENT_ID, // Your client id here
    TokenScopesArray : ["phone", "email", "profile","openid", "aws.cognito.signin.user.admin", "accesstoken"],
    AppWebDomain : REACT_APP_WEB_DOMAIN,
    RedirectUriSignIn : REACT_APP_SIGN_IN_REDIRECT_URI,
    RedirectUriSignOut : REACT_APP_WEB_DOMAIN,
    IdentityProvider : "Facebook", // e.g. 'Facebook',
    UserPoolId : REACT_APP_COGNITO_IDENTITY_POOL_ID, // Your user pool id here
};
const auth = new CognitoAuth(authData);

export default auth;
