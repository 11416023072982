import { createRequestTypes } from "../../helpers/constants";

export const SAVE_SETTINGS = createRequestTypes("SAVE_SETTINGS");
export const GET_SETTINGS = createRequestTypes("GET_SETTINGS", { INIT_DEFAULT_VALUE: "INIT_DEFAULT_VALUE" });
export const UPDATE_ROBOTS = createRequestTypes("UPDATE_ROBOTS");

export const saveSettings = {
    request: request => ({ type: SAVE_SETTINGS.REQUEST, request }),
    success: response => ({ type: SAVE_SETTINGS.SUCCESS, response }),
    error:   error => ({ type: SAVE_SETTINGS.FAILURE, error })
};

export const getConfig = {
    request: request => ({ type: GET_SETTINGS.REQUEST, request }),
    success: response => ({ type: GET_SETTINGS.SUCCESS, response }),
    error:   error => ({ type: GET_SETTINGS.FAILURE, error }),
    initDefaultValue:   data => ({ type: GET_SETTINGS.INIT_DEFAULT_VALUE, data })
};

export const updateRobots = {
    request: request => ({ type: UPDATE_ROBOTS.REQUEST, request }),
    success: response => ({ type: UPDATE_ROBOTS.SUCCESS, response }),
    error:   error => ({ type: UPDATE_ROBOTS.FAILURE, error }),
};
