import * as actions from "../actions";

const initialState = {
    loading: false,
    message: "",
    domains: [],
    existsDomains: [],
    checkedDomains: [],
    domainsError: "",
};

/**
 * @author Roman Yephimov <ry@piogroup.net>
 * @desc Auth domains Reducer
 * @param {Object} state - The current state
 * @param {Object} action - The action data
 */
const domains = (state = initialState, action) => {
    switch (action.type) {
        case actions.AUTH_GET_DOMAINS.FAILURE:
            return Object.assign({}, state, {
                loading: false,
                domainsError: action.error,
            });

        case actions.AUTH_GET_DOMAINS.SUCCESS:
            return Object.assign({}, state, {
                loading: false,
                domainsError: "",
                domains: action.response.filter(site => !site.isExist),
                existsDomains: action.response.filter(site => site.isExist),
            });

        case actions.AUTH_GET_DOMAINS.REQUEST:
            return Object.assign({}, state, {
                loading: true,
                message: "",
            });

        case actions.CHECK_DOMAIN:
            return Object.assign({}, state, {
                loading: false,
                checkedDomains: state.checkedDomains.indexOf(action.data) !== -1 ?
                    state.checkedDomains.filter(domain => domain !== action.data )
                    :
                    [...state.checkedDomains, action.data],
            });

        case actions.CLEAR_CHECKED_DOMAINS:
            return Object.assign({}, state, {
                loading: false,
                checkedDomains: [],
            });

        case actions.CHOOSE_DOMAINS.REQUEST:
            return {
                ...state,
                loading: true,
                message: "",
            };

        case actions.CHOOSE_DOMAINS.SUCCESS:
            return {
                ...state,
                loading: false,
                message: "",
            };

        case actions.CHOOSE_DOMAINS.FAILURE:
            return {
                ...state,
                loading: false,
                message: action.error,
            };

        default:
            return state;
    }
};

export default domains;
