// @flow
import React, { Component } from "react";
import global from "../../../assets/scss/global.module.scss";
import styles from "./anchorBlock.module.scss";
import type { AnchorBlockPropsType, AnchorBlockStateType } from "./AnchorBlockType";
import Scrollspy from 'react-scrollspy'

class AnchorBlock extends Component<AnchorBlockPropsType, AnchorBlockStateType> {
    state = {
        selected: 0
    }

    handleChange = (index: number) => {
        this.setState({ selected: index });
        this.props.handleClick && this.props.handleClick(index);
    }

    render() {
        return (
            <div className={`${global["bg-white"]} ${global["with-shadow"]} ${styles["anchor-block-wrapper"]}`}>
                 <Scrollspy items={['section-1', 'section-2', 'section-3', 'section-4']} 
                    currentClassName={`${styles["selected"]}`}
                    className={`${styles["anchor-block"]} ${global["d-flex"]} ${global.list}`}
                    offset={-200}
                    >
                    {this.props.children.map((elem, index) => {
                        return <li
                            className={`${styles[index === this.state.selected ? "selected" : null]}`}
                            key={index}
                            onClick={this.handleChange.bind(this, index)} ><div id={`#section-${index+1}`}>{elem} </div></li>;
                    })}
                </Scrollspy>
            </div>
        );
    }
}

export default AnchorBlock;

