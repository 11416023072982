import { put, take, fork, call } from "redux-saga/effects";
import { API } from "aws-amplify";
import * as actions from "./actions";
import * as userActions from "actions/user";
import dotenv from "dotenv";

import { premiumResponse } from "../../config/offlineData";


dotenv.load();

/**
 * @author Roman Yephimov <ry@piogroup.net>
 * @desc Makes Sign Up request
 */
function* watchActivateSite() {
    while (true) {
        const { request: { domain, action } } = yield take(actions.ACTIVATE_SITE.REQUEST);
        try {
            const reqBody = {
                body: { ...domain },
            };
            let response = {}
            if(process.env.REACT_APP_MOCK === 'true'){
                response  = {data:yield premiumResponse};
            } else {
                response = yield API.post("auctollo", "/site/premium", reqBody);
                console.log('response', response)
            }
            const { data } = response
            yield put(actions.activateSite.success());
            yield put(userActions.updateActiveDomain(data));
            yield call(action);
        } catch (e) {
            yield put(actions.activateSite.error(e.message));
        }
    }
}

export default [
    fork(watchActivateSite),
];
