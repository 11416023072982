// @flow

import React from "react";
import { AreaChart, XAxis, YAxis, CartesianGrid, Tooltip, Area, Legend, ResponsiveContainer } from "recharts";
import { googleWebMaserChartConfig } from "../../../config/chartsConfigs";

import type { AreaChartPropTypes } from "./AreaChartTypes";

const AreaChartComponent = (props: AreaChartPropTypes) => {
    const { chartData } = props;
    return (
        <ResponsiveContainer aspect={3}>
            <AreaChart
                data={chartData}
                margin={{ top: 30, right: 30, left: 30, bottom: 30 }}>
                <defs>
                    {googleWebMaserChartConfig.map((item, i) => {
                        return (
                            <linearGradient key={i} id={`color${item.name}`} x1="0" y1="0" x2="0" y2="1">
                                <stop offset="0%" stopColor={item.color} stopOpacity={0.3}/>
                                <stop offset="95%" stopColor="#ffffff" stopOpacity={0}/>
                            </linearGradient>
                        );
                    })}
                </defs>
                <XAxis dataKey="name" padding={{ left: 30, right: 30 }} />
                <YAxis />
                <CartesianGrid strokeDasharray="5 8" vertical={false} />
                <Tooltip />
                <Legend />
                {
                    googleWebMaserChartConfig.map((item, i) => {
                        return (
                            <Area
                                key={i}
                                dataKey={item.name}
                                dot={{ fill: item.color, stroke: "none" }}
                                stroke={item.color}
                                fillOpacity={1}
                                fill={`url(#${item.name})`}
                            />
                        );
                    })
                }
            </AreaChart>
        </ResponsiveContainer>
    );
};

export default AreaChartComponent;
