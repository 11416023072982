// @flow
import React, { Component } from "react";
import global from "../../../assets/scss/global.module.scss";
import styles from "./tabs.module.scss";
import type { TabsPropsType, TabsStateType } from "./TabsType";

class Tabs extends Component<TabsPropsType, TabsStateType> {
    state = {
        selected: 0
    }

    handleChange(index: number) {
        this.setState({ selected: index });
    }

    render() {
        return (<div>
            <ul className={`${styles["tabs"]} ${global["d-flex"]} ${global.list} ${global["bg-white"]} ${global["with-shadow"]}`}>
                {this.props.children.map((elem, index) => {
                    return <li
                        className={`${styles[index === this.state.selected ? "selected" : null]}`}
                        key={index}
                        onClick={this.handleChange.bind(this, index)}>{elem.props.title}</li>;
                })}
            </ul>
            <div className={`${styles["tab-content"]}`}>{this.props.children[this.state.selected]}</div>
        </div>
        );
    }
}

export default Tabs;
