import React from "react";
import Messages from "UI/messages";
import { TopPanel } from "common/components";
import withUser from "helpers/withUser";
import ServiceWorkerNotifier from "./../serviceWorkerNotifier";

const Layout = ({ children, ...rest }, ) => {
    return (
        <>
            <TopPanel {...rest}/>
            <Messages />
            <ServiceWorkerNotifier />
            {children}
        </>
    );
};

export default withUser(Layout);
