// @flow

import { call, put, take, fork } from "redux-saga/effects";
import http from "../../api";
import * as actions from "./actions";
import { API } from "aws-amplify/lib/index";
import { INITIAL_SETTINGS_VALUE } from "../../constants";
import { isObject } from "lodash";
import { siteConfig } from "../../config/offlineData";

const { REACT_APP_S3_BUCKET, REACT_APP_REGION } = process.env;

/**
 * @author Alex Malyi <am@piogroup.net>
 * @desc Save Settings Request
 */
function* watchSaveSettings() {
    while (true) {
        const { request } = yield take(actions.SAVE_SETTINGS.REQUEST);
        try {
            if (request) {
                let { domain, settings } = request;

                Object.keys(settings).forEach(item => {
                    isObject(settings[item]) && settings[item].label ? settings[item] = settings[item].label.toLowerCase() : item;
                });

                const sm_b_exclude_cats = Object.keys(settings)
                    .filter(item => item.includes("ex_cat_") && settings[item] === true)
                    .map(item => {
                        return Number(item.slice(7));
                    });

                const sm_in_customtypes = Object.keys(settings)
                    .filter(item => item.includes("posttype_") && settings[item] === true)
                    .map(item => {
                        return item.slice(9);
                    });

                settings = {
                    ...settings,
                    sm_b_exclude_cats,
                    sm_in_customtypes
                };

                const config = {
                    body: { domain, settings },
                    response: true,
                };

                if(process.env.REACT_APP_MOCK === 'true'){
                    localStorage.setItem('site-config', JSON.stringify(settings))
                } else {
                    yield API.post("auctollo", "/site/config-create", config);
                }

                yield put(actions.saveSettings.success());
            }
        } catch (e) {
            yield put(actions.saveSettings.error(e));
        }
    }
}

/**
 * @author Alex Malyi <am@piogroup.net>
 * @desc Generate SiteMap request
 */
function* watchGetSettings() {
    while (true) {
        const { request } = yield take(actions.GET_SETTINGS.REQUEST);
        try {
            if (request) {
                let { domain } = request;
                const config = {
                    body: {
                        domain: domain.split("://")[1],
                    },
                    response: true,
                };
                let response = {}
                let site_config =  localStorage.getItem('site-config') || '{}';
                if(process.env.REACT_APP_MOCK === 'true'){
                    response = {data:yield site_config !== '{}' ? {data:JSON.parse(site_config)} : siteConfig}
                } else {
                   response = yield API.post("auctollo", "/site/site-config", config);
                }
                yield put(actions.getConfig.success(response.data));
            }
        } catch (e) {
            // yield put(actions.getConfig.initDefaultValue(INITIAL_SETTINGS_VALUE)); // todo
            yield put(actions.getConfig.error(e));
        }
    }
}

/**
 * @author Alex Malyi <am@piogroup.net>
 * @desc Read robots.txt file
 */
function* watchUpdateRobot() {
    while (true) {
        const { request } = yield take(actions.UPDATE_ROBOTS.REQUEST);
        try {
            if (request && request.url) {
                const { url } = request;
                const auctollo_bucket_url = `https://${String(REACT_APP_S3_BUCKET)}.s3.${String(REACT_APP_REGION)}.amazonaws.com`;
                const callUrl = `${url}/wp-json/jwt-auth/v1/check_robot`;
                const response = yield call(http, callUrl, {
                    method: "POST",
                    body: JSON.stringify({ auctollo_bucket_url: auctollo_bucket_url })
                });

                if (response.success){
                    yield put(actions.updateRobots.success(response));
                }
            }
        } catch (e) {
            yield put(actions.updateRobots.error(e));
        }
    }
}

export default [
    fork(watchSaveSettings),
    fork(watchGetSettings),
    fork(watchUpdateRobot),
];
