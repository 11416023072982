import React, { Component } from "react";
import { Loader } from "common/components";

export default function asyncComponent(importComponent) {
    class AsyncComponent extends Component {
        state = {
            component: null,
            error: null
        };

        componentDidCatch(error) {
            this.setState({ error });
        }

        async componentDidMount() {
            const { default: component } = await importComponent();

            this.setState({
                component: component
            });
        }

        render() {
            const C = this.state.component;

            return C ? <C {...this.props} /> : <Loader isLoading={true} error={this.state.error} />;
        }
    }

    return AsyncComponent;
}
