// @flow

import React, { Component, createRef } from "react";
import Slider from "react-slick";
import fakeChart from "assets/img/fake-chart.png";
import { Label } from "..";
import { CheckIcon } from "../../../../src/assets/icons";
import type { ChartSliderStateType } from "./chartSliderType";

class ChartSlider extends Component<{}, ChartSliderStateType> {
    state = {
        nav1: null,
        nav2: null
    }

    componentDidMount() {
        this.setState({
            nav1: this.slider1,
            nav2: this.slider2
        });
    }

    slider1: * = createRef();
    slider2: * = createRef();

    render() {
        return (
            <div className="chart-slider-wrapper">
                <div className="top-slider">
                    <Slider
                        asNavFor={this.state.nav2}
                        ref={slider => (this.slider1 = slider)}
                    >
                        <div>
                            <div className="chart">
                                <img src={fakeChart} />
                            </div>
                            <div className="chart-details">
                                <div className="chart-title d-flex">
                                    <span className="label">Sitemap <span className="value">url-sitemap/HTML</span></span>
                                    <span className="label">Type <span className="value">HTML</span></span>
                                </div>
                                <div className="chart-content-list d-flex">
                                    <div className="list-item">
                                        <span className="label">Status</span>
                                        <span className="value">
                                            <Label
                                                labelIcon={<CheckIcon />}
                                                labelText="Active"
                                                labelConfig="active" />
                                        </span>
                                    </div>
                                    <div className="list-item">
                                        <span className="label">Processed</span>
                                        <span className="value">
                                            Yes
                                        </span>
                                    </div>
                                    <div className="list-item">
                                        <span className="label">Submited</span>
                                        <span className="value">
                                            3224
                                        </span>
                                    </div>
                                    <div className="list-item">
                                        <span className="label">Indexed</span>
                                        <span className="value">
                                            1323
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <h3>Slide 2</h3>
                        </div>
                        <div>
                            <h3>Slide 3</h3>
                        </div>
                        <div>
                            <h3>Slide 4</h3>
                        </div>
                        <div>
                            <h3>Slide 5</h3>
                        </div>
                        <div>
                            <h3>Slide 6</h3>
                        </div>
                    </Slider>
                </div>

                <div className="slider-nav">
                    <Slider
                        asNavFor={this.state.nav1}
                        ref={slider => (this.slider2 = slider)}
                        slidesToShow={5}
                        swipeToSlide={true}
                        focusOnSelect={true}
                    >
                        <div>
                            <span>HTML</span>
                        </div>
                        <div>
                            <span>Image</span>
                        </div>
                        <div>
                            <span>Regular</span>
                        </div>
                        <div>
                            <span>Video</span>
                        </div>
                        <div>
                            <span>News</span>
                        </div>
                        <div>
                            <span>Page</span>
                        </div>
                    </Slider>
                </div>
            </div>
        );
    }
}

export default ChartSlider;
