//@flow

import React from "react";
import global from "./../../../assets/scss/global.module.scss";
import styles from "./chartLegend.module.scss";

import type { ChartLegendType } from "./ChartLegendType.js";

const ChartLegend = (props: ChartLegendType) => {
    return(
        <div className={`${styles["chart-legend"]} ${global["d-flex"]} ${global["align-items-center"]} ${styles[props.active && "active"]}`}>
            <span className={styles["legend-dot"]}></span>
            <span className={styles["legend-title"]}>Server Errors</span>
        </div>
    );
};

export default ChartLegend;
