import * as actions from "../actions";

const initialState = {
    loading: false,
    message: "",
};

const mySitesData = ( state = initialState, action ) => {
    switch (action.type) {

        case actions.DISPLAY_MY_SITES_DATA.FAILURE:
            return Object.assign( {}, state, {
                loading: false,
                message: action.error,
            } );

        case actions.DISPLAY_MY_SITES_DATA.SUCCESS:
            return Object.assign( {}, state, {
                loading: false,
                message: "",
                data: action.data
            } );

        case actions.DISPLAY_MY_SITES_DATA.REQUEST:
            return Object.assign( {}, state, {
                loading: true,
                message: "",
            } );

        case actions.CLEAR_MY_SITES_DATA.SUCCESS:
            return Object.assign( {}, state, {
                loading: false,
                message: "",
                data: {}
            } );

        default:
            return state;
    }
};

export default mySitesData;
