import { all } from "redux-saga/effects";
import AuthSagas from "../components/dashboard/sagas";
// import SignUpSagasDEPRECATED from "../components/signUp_DERPECATED/sagas";
import SignUpSagas from "components/signup/sagas";
import SaveSettingsSagas from "../components/settings/sagas";
import SignInSagas from "../components/signIn/sagas";
import PaymentSagas from "../components/payment/sagas";
import PaymentBraintreeSagas from "../components/paymentBraintree/sagas";
import SubscriptionInfoSagas from "../components/subscriptionInfo/sagas";
import MySitesSagas from "../components/mySites/sagas";
import PaymentUpdateMethodSagas from "../components/paymentUpdateMethod/sagas";

/**
 * @author Alex Malyi <am@piogroup.net>
 * @desc Root saga which include all sagas in project
 */
export default function* rootSaga() {

    const sagas = [
        ...AuthSagas,
        ...SignUpSagas,
        // ...SignUpSagasDEPRECATED,
        ...SaveSettingsSagas,
        ...SignInSagas,
        ...PaymentSagas,
        ...PaymentBraintreeSagas,
        ...PaymentUpdateMethodSagas,
        ...SubscriptionInfoSagas,
        ...MySitesSagas
    ];

    yield all(sagas);
}
