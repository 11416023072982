import { createRequestTypes } from "../../helpers/constants";

export const SIGN_UP = createRequestTypes("SIGN_UP");
export const CONFIRM_USER = createRequestTypes("CONFIRM_USER");
export const AUTH_GET_DOMAINS = createRequestTypes("AUTH_GET_DOMAINS");
export const CHOOSE_DOMAINS = createRequestTypes("CHOOSE_DOMAINS");

export const CHECK_DOMAIN = "CHECK_DOMAIN";
export const CLEAR_CHECKED_DOMAINS = "CLEAR_CHECKED_DOMAINS";

export const signUp = {
    request: request => ({ type: SIGN_UP.REQUEST, request }),
    success: () => ({ type: SIGN_UP.SUCCESS }),
    error:   error => ({ type: SIGN_UP.FAILURE, error })
};

export const confirmUser = {
    request: request => ({ type: CONFIRM_USER.REQUEST, request }),
    success: response => ({ type: CONFIRM_USER.SUCCESS, response }),
    error:   error => ({ type: CONFIRM_USER.FAILURE, error })
};

export const authGetDomains = {
    request: request => ({ type: AUTH_GET_DOMAINS.REQUEST, request }),
    success: response => ({ type: AUTH_GET_DOMAINS.SUCCESS, response }),
    error:   error => ({ type: AUTH_GET_DOMAINS.FAILURE, error })
};

export const chooseDomains = {
    request: request => ({ type: CHOOSE_DOMAINS.REQUEST, request }),
    success: response => ({ type: CHOOSE_DOMAINS.SUCCESS, response }),
    error:   error => ({ type: CHOOSE_DOMAINS.FAILURE, error })
};

export const checkDomain = data => ({ type: CHECK_DOMAIN, data });
