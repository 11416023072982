// @flow
export default {
    signupUnconfirmedForm: {
        id: "signupUnconfirmedForm"
    },
    codeVerify: {
        id: "codeVerify"
    },
    wpVerify: {
        id: "wpVerify"
    },
    chooseDomains: {
        id: "chooseDomains"
    }
};
