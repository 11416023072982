//@flow

import React from "react";
import { cutString, prepareDataToPercent, cutNumber } from "helpers";

import styles from "./table.module.scss";

import type { TableWebmasterPropsTypes } from "./TableWebmasterPropsTypes";

const emptyDataItem = {
    clicks: "---",
    ctr: "---",
    impressions: "---",
    keys: ["---------------"],
    position: "---",
};

const emptyData = [emptyDataItem, emptyDataItem, emptyDataItem, emptyDataItem, emptyDataItem];

const TableWebmaster = (props: TableWebmasterPropsTypes) => {
    const { data } = props;
    const rowsData = data && data.length > 0 ? data : emptyData;
    return (
        <table className={styles.table}>
            <thead>
                <tr>
                    <th>Path</th>
                    <th>Clicks</th>
                    <th>Impressions</th>
                    <th>CTR</th>
                    <th>Position</th>
                </tr>
            </thead>
            <tbody>
                {rowsData.map((item, index) => {
                    return (
                        <tr key={index}>
                            <td>{cutString(item.keys[0])}</td>
                            <td>{item.clicks}</td>
                            <td>{item.impressions}</td>
                            <td>{prepareDataToPercent(item.ctr)}</td>
                            <td>{cutNumber(item.position, 0)}</td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};

export default TableWebmaster;
