//@flow

import React from "react";
//import { cutString, prepareDataToPercent, cutNumber } from "helpers";

import styles from "./table.module.scss";

import type { TableSitemapPropsTypes } from "./TableSitemapPropsTypes";

const emptyDataItem = {
    path: ["---------------"],
    types: "---",
    processed: "---",
    issues: "---",
    submited: "---",
    indexed: "---",
};

const emptyData = [emptyDataItem, emptyDataItem, emptyDataItem, emptyDataItem, emptyDataItem, emptyDataItem];

const TableSitemap = (props: TableSitemapPropsTypes) => {
    const { data } = props;
    const rowsData = data && data.length > 0 ? data : emptyData;
    return (
        <table className={styles.table}>
            <thead>
                <tr>
                    <th>Site maps</th>
                    <th>Types</th>
                    <th>Processed</th>
                    <th>Issues</th>
                    <th>Submited</th>
                    <th>Indexed</th>
                </tr>
            </thead>
            <tbody>
                {rowsData.map((item, index) => {
                    return (
                        <tr key={index}>
                            <td>{item.path}</td>
                            <td>{item.contents instanceof Array && item.contents.length > 0 && item.contents[0].type}</td>
                            <td>{ "---" }</td>
                            <td>{item.issues}</td>
                            <td>{item.contents instanceof Array && item.contents.length > 0 && item.contents[0].submitted}</td>
                            <td>{item.contents instanceof Array && item.contents.length > 0 && item.contents[0].indexed}</td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};

export default TableSitemap;
