// @flow
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import userImage from "assets/img/user.png";

//styles
import global from "assets/scss/global.module.scss";
import styles from "./dropdown.module.scss";

//types
import type { DropdownPropType, DropdownStateType } from "./DropdownType";

class Droprdown extends Component<DropdownPropType, DropdownStateType> {
    state = {
        opened: false
    };
    toggleMenu = () => this.setState({ opened: !this.state.opened });

    render() {

        const { history, handleLogout, user: { userData : { attributes: { name } } } } = this.props;

        return (
            <div className={styles["dropdown-wrapper"]}>
                <button type="button" className={`${styles["toggle-btn"]} ${global["d-flex"]} ${global["align-items-center"]}`} onClick={this.toggleMenu}>
                    <span className={styles["logged-as"]}>Logged in as</span>
                    <img src={userImage} alt="user"/>
                    <span>{name}</span>
                </button>
                <ul className={`${global.list} ${global["bg-white"]} ${global["with-shadow"]} ${styles["dropdown-menu"]} ${styles[this.state.opened && "opened"]}`}>
                    <li className={global["d-flex"]}>
                        <button className={styles["dropdown-link"]} onClick={() => { history.push("/"); }}>Dashboard</button>
                    </li>
                    <li className={global["d-flex"]}>
                        <button className={styles["dropdown-link"]} onClick={() => { history.push("/settings"); }}>Settings</button>
                    </li>
                    <li className={global["d-flex"]}>
                        <button className={`${styles["dropdown-link"]} ${styles["upgrade-link"]}`} onClick={() => { history.push("/pricing-plans"); }}>Upgrade to Premium <span className={styles.badge}>1</span></button>
                    </li>
                    <li className={global["d-flex"]}>
                        <button className={styles["dropdown-link"]} onClick={() => {history.push("/my-sites")}}>My Sites</button>
                    </li>
                    <li className={global["d-flex"]}>
                        <button className={styles["dropdown-link"]} onClick={() => { history.push("/subscription-info"); }}>Subscription Info</button>
                    </li>
                    <li className={global["d-flex"]}>
                        <button className={styles["dropdown-link"]} onClick={() => handleLogout()}>Log Out</button>
                    </li>

                </ul>
            </div>
        );
    }
}

export default withRouter(Droprdown);
