import { createRequestTypes } from "../../helpers/constants";

export const DISPLAY_MY_SITES_DATA = createRequestTypes("DISPLAY_MY_SITES_DATA");
export const CLEAR_MY_SITES_DATA = createRequestTypes("CLEAR_MY_SITES_DATA");

export const displayMySitesData = {
    request: request => ({ type: DISPLAY_MY_SITES_DATA.REQUEST, request }),
    success: data => ({ type: DISPLAY_MY_SITES_DATA.SUCCESS, data }),
    error:   error => ({ type: DISPLAY_MY_SITES_DATA.FAILURE, error })
};

export const clearMySitesData = {
    success: () => ({ type: CLEAR_MY_SITES_DATA.SUCCESS }),
};
