import { JWT_TOKEN_KEY, USER, USER_DATA, ACTIVE_SITE, USER_SITES } from "constants/index";

export default class Storage {
    /**
     * @author Alex Malyi <am@piogroup.net>
     * @desc Save access token
     * @param token
     */
    static setToken(token) {
        localStorage.setItem(JWT_TOKEN_KEY, token);
    }

    /**
     * @author Roman Yephimov <ry@piogroup.net>
     * @desc Save user
     * @param user
     */
    static setUser(user) {
        localStorage.setItem(USER, JSON.stringify(user));
    }

    /**
     * @author Roman Yephimov <ry@piogroup.net>
     * @desc Save user data
     * @param userData
     */
    static setUserData(userData) {
        localStorage.setItem(USER_DATA, JSON.stringify(userData));
    }

    /**
     * @author Roman Yephimov <ry@piogroup.net>
     * @desc Save some item
     * @param name {String} - Name of item in local storage
     * @param item {any} - Item which will save
     */
    static setItem(name, item) {
        localStorage.setItem(name, JSON.stringify(item));
    }

    /**
     * @author Roman Yephimov <ry@piogroup.net>
     * @desc Save some item
     * @param name {String} - Name of item in local storage
     */
    static getItem(name) {
        return localStorage.getItem(name) ? JSON.parse(localStorage.getItem(name)) : null;
    }

    /**
     * @author Roman Yephimov <ry@piogroup.net>
     * @desc Get user
     */
    static getUser() {
        return localStorage.getItem(USER) ? JSON.parse(localStorage.getItem(USER)) : null;
    }

    /**
     * @author Roman Yephimov <ry@piogroup.net>
     * @desc Get user data
     */
    static getUserData() {
        return localStorage.getItem(USER_DATA) ? JSON.parse(localStorage.getItem(USER_DATA)) : null;
    }

    /**
     * @author Alex Malyi <am@piogroup.net>
     * @desc Get access token
     */
    static getToken() {
        return localStorage.getItem(JWT_TOKEN_KEY) ? `Bearer ${localStorage.getItem(JWT_TOKEN_KEY)}` : "";
    }

    /**
     * @author Alex Malyi <am@piogroup.net>
     * @desc Clear storage
     */
    static clearStorage() {
        localStorage.clear();
    }

    /**
     * @author Alex Malyi <am@piogroup.net>
     * @desc Make logout with local storage
     */
    static logout() {
        localStorage.removeItem(JWT_TOKEN_KEY);
        localStorage.removeItem(USER);
        localStorage.removeItem(USER_DATA);
        localStorage.removeItem(ACTIVE_SITE);
        localStorage.removeItem(USER_SITES);
    }
}
