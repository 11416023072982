import { put, take, fork, call } from "redux-saga/effects";
import { API } from "aws-amplify";
import * as actions from "./actions";
import * as userActions from "actions/user";
import dotenv from "dotenv";

import { premiumResponse } from "../../config/offlineData";
dotenv.load();

const {
    REACT_APP_API_GATEWAY_URL
} = process.env;

function* watchDisplaySubscriptionInfo() {
    while (true) {
        const { request: { domain, action } } = yield take(actions.DISPLAY_SUBSCRIPTION_INFO.REQUEST);
        console.log("Got display request");
        try {

            let response = {};
            console.log("About to call the lambda backend for subscription info");
            if(process.env.REACT_APP_MOCK === 'true'){
                response  = {};
            } else {

                // response = yield API.post("auctollo", "/payment/get-subscription-info", reqBody);
                let body =JSON.stringify({domain});
                console.log("body:");
                console.log(body);
                response = yield fetch(`${REACT_APP_API_GATEWAY_URL}/payment/subscriptionInfo`, {method: "POST", body});

            }
            const responseObject = yield response.json();
            let jsonData = JSON.parse(responseObject.body);
            yield put(actions.displaySubscriptionInfo.success(jsonData.data));
        } catch (e) {
            console.log("error in watchDisplaySubscriptionInfo");
            console.log(e);
            yield put(actions.displaySubscriptionInfo.error(e.message));
        }
    }
}

export default [

    fork(watchDisplaySubscriptionInfo),
];
