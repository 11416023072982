// @flow
import React from "react";
import Select from "react-select";

// types
import type { FormSelectType } from "./FormSelectTypes";

// styles
import styles from "./select.module.scss";

const FormSelect = ({ input, meta: { touched, error }, data, fieldType, options, ...rest } : FormSelectType ) => {
    let errorBlock = null;
    let borderColor = "";
    if (touched && error) {
        errorBlock = (
            <div className={styles["error-block"]}>
                <span>{error}</span>
            </div>
        );
        borderColor = "select-error";
    }
    const customStylesMenu = {
        option: () => ({
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: 14,
            color: "black",
            background: "#FFFFFF",
            padding: "8px 16px",
            ":hover":{
                backgroundColor: "#F2F9F7",
                color: "#36ACA3"
            }
        })
    };
    const generateOptionTitle = (label, value) => {
        if (fieldType === "frequencies") {
            return { label: label.charAt(0).toUpperCase() + label.slice(1), value };
        }
        return { label, value };
    };
    const dataOptions = () => {
        return options ? options : optionsSettings();
    };
    const optionsSettings = () => data.map((label, value) => generateOptionTitle(label, value));

    const generateValue = () => {
        if (input.value!== null && typeof(input.value) === "object") {
            return input.value;
        } else if (fieldType === "frequencies") {
            return {
                label: input.value.charAt(0).toUpperCase() + input.value.slice(1),
                value: input.value
            };
        } else {
            return { label: input.value, value: input.value };
        }

    };

    return (
        <div className={data ? "selectSet" : "selectReg"}>
            <Select
                {...input}
                {...rest}
                options={dataOptions()}
                value={generateValue()}
                onChange={value => {
                    input.onChange(value);
                }}
                onBlur={() => input.onBlur(input.value)}
                className={`${"customeSelect"} ${borderColor}`}
                classNamePrefix="react-select"
                styles={customStylesMenu}
            />
            {errorBlock}
        </div>
    );
};

export default FormSelect;
