// @flow
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import * as actions from "actions/appSettings";
import Router from "router";
import * as DashboardActions from "components/dashboard/actions";
import { GoogleApiHelper } from "helpers/index";

type AppPropsType = {
    setPlatform: (platform: boolean) => void,
    onRefershToken: ({type: string, token: Object}) => void
}

class App extends PureComponent<AppPropsType> {

    componentDidMount() {
       
        const { dataset: { platform } } = document.getElementById("root") || {};   
        if (platform) {
            this.props.setPlatform(true);
        }
    }

    render () {
        return <Router />;
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setPlatform: platform => {
            dispatch(actions.setPlatform(platform));
        },
        onRefershToken: data => {
            dispatch(DashboardActions.setRefreshToken.request(data));
        }
    };
};

export default connect(null, mapDispatchToProps)(App);
