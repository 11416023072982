import { put, take, fork, call } from "redux-saga/effects";
import { API } from "aws-amplify";
import * as actions from "./actions";
import * as userActions from "actions/user";
import dotenv from "dotenv";

import { premiumResponse } from "../../config/offlineData";

dotenv.load();

const {
    REACT_APP_API_GATEWAY_URL
} = process.env;

function* watchPaymentUpdateMethod() {
    while (true) {
        const { request: { nonce, action } } = yield take(actions.PAYMENT_UPDATE_METHOD.REQUEST);
        try {
            const reqBody = {
                body: { ...nonce },
            };

            console.log("entered watchPaymentUpdateMethod");

            let response = {}
            if(process.env.REACT_APP_MOCK === 'true'){
                // response  = {data:yield premiumResponse};
                response  = {data: {}};
            } else {

                let body =JSON.stringify({"paymentMethodNonce": nonce});
                response = yield fetch(`${REACT_APP_API_GATEWAY_URL}/payment/updateMethod`, {method: "POST", body});
                // console.log('response', response);
            }
            const { data } = response
            yield put(actions.paymentUpdateMethod.success());
            yield call(action);
        } catch (e) {
            console.log("dd error:");
            console.log(e);
            yield put(actions.paymentUpdateMethod.error(e.message));
        }
    }
}

export default [
    fork(watchPaymentUpdateMethod),
];
