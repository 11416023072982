// @flow
import { PATH_LENGTH } from "constants";
import { googleWebmasterHeaderConfig } from "components/dashboard/mockedData";
import moment from "moment";

type webmasterRequestBody = {
    startDate: string,
    endDate: string,
};

type rowItem = {
    clicks: number,
    ctr: number,
    impressions: number,
    keys: Array<string>,
    position: number,
}

export const cutString = (string: string) => {
    return `${string.slice(0, PATH_LENGTH)}${string.length > PATH_LENGTH ? "..." : ""}`;
};

export const getTotalSum = (data: Array<rowItem>, key: string) => {
    return data.reduce((prev, cur) => prev + cur[key], 0);
};

export const cutNumber = (data: string | number, lengthAfterComma: number = 1) => {
    if (typeof data === "string") return data;
    return Number(data).toFixed(lengthAfterComma);
};

export const prepareDataToPercent = (data: string | number) => {
    if (typeof data === "string") return data;
    return `${Number(data * 100).toFixed(1)} %`;
};

export const prepereBigNumber = (count: number) => {
    if(count < 9999) {
        return count;
    }
    if(count < 1000000) {
        return `${(count/1000).toFixed(1)} K`;
    }
    if(count < 10000000) {
        return `${(count/1000000).toFixed(1)} M`;
    }
    if(count < 1000000000) {
        return `${Math.round(count/1000000)} M`;
    }
    if(count < 1000000000000) {
        return `${Math.round(count/1000000000)} B`;
    }
};

export const preparetotalClick = (data: Array<rowItem>) => {
    let count = getTotalSum(data, "clicks");
    return prepereBigNumber(count);
};

export const prepareaverageViews = (data: Array<rowItem>) => {
    let count = getTotalSum(data, "impressions");
    return prepereBigNumber(count);
};

export const prepareaverageCtr = (data: Array<rowItem>) => {
    let count = getTotalSum(data, "ctr") / data.length;
    return count !== 0 ? `${Number(count * 100).toFixed(1)} %` : "0 %";
};

export const prepareaveragePosition = (data: Array<rowItem>) => {
    let count = getTotalSum(data, "position");
    return count !== 0 ? +Number(count / data.length).toFixed(1) : 0;
};

export const prepareHeaderData = (data: Array<rowItem>) => {
    const prepare = { preparetotalClick, prepareaverageViews, prepareaverageCtr, prepareaveragePosition };
    return googleWebmasterHeaderConfig.map(item => {
        item.value = prepare[`prepare${item.type}`](data);
        return item;
    });
};

const getRangeName = (date: moment, type: string) => {
    switch (true) {
        case type === "month":
            return `${date.format("MMM")} ${date.format("YYYY")}`;
        case type === "day":
            return `${date.format("DD")} ${date.format("MMM")} ${date.format("YYYY")}`;
    }
};

const getSumTypeByDate = (data: Array<rowItem>, item: { date: string }, type: string) => {
    return data.reduce((prev, cur) => {
        return cur.keys[0].split("-")[1] === item.date.split("-")[1] ?
            prev + cur[type] :
            prev;
    }, 0);
};

export const prepareChartData = (data: Array<rowItem>, requestBody: webmasterRequestBody) => {

    const { startDate, endDate } = requestBody;
    let type = data.length >= 62 ? "month" : "day"; // TODO refactor when implement pagination
    const end = moment(endDate);
    let listRangeEntities = [];
    let counter = moment(startDate);

    while( counter <= end ) {
        listRangeEntities.push({
            date: counter.format("YYYY-MM"),
            "name": getRangeName(counter, type),
            "Clicks": 0,
            "Impressions": 0,
            "CTR": "0",
            "Position": "0",
        });
        counter.add(1, type);
    }

    listRangeEntities.map(item => {
        item["Clicks"] = getSumTypeByDate(data, item, "clicks");
        item["Impressions"] = getSumTypeByDate(data, item, "impressions");
        item["CTR"] = getSumTypeByDate(data, item, "ctr").toFixed(2);
        item["Position"] = (getSumTypeByDate(data, item, "position") / moment(item.date).daysInMonth()).toFixed(2);
        return item;
    });
    return listRangeEntities;
};
