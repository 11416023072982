// @flow

import React from "react";
import { Link } from "react-router-dom";
import global from "../../../../assets/scss/global.module.scss";
import styles from "./navigation.module.scss";

const Navigation = () => {
    return (
        <ul className={`${global["d-flex"]} ${global["list"]} ${styles["nav-menu"]}`}>
            <li className={styles["list-item"]}>
                <a href="#" className={styles["nav-link"]}>Product</a>
            </li>
            <li className={styles["list-item"]}>
                <a href="#" className={styles["nav-link"]}>Pricing</a>
            </li>
            <li className={styles["list-item"]}>
                <a href="#" className={styles["nav-link"]}>Support</a>
            </li>
            <li className={styles["list-item"]}>
                <a href="#" className={styles["nav-link"]}>Blog</a>
            </li>
            <li className={styles["list-item"]}>
                <Link to="/sign-in" href="#" className={styles["nav-link"]}>Login</Link>
            </li>
            <li className={styles["list-item"]}>
                <Link to="/sign-up" href="#"  className={`${styles["nav-link"]} ${styles["get-started-link"]}`}>Get Started - Free</Link>
            </li>
        </ul>
    );
};

export default Navigation;
