import { createRequestTypes } from "helpers/constants";

export const AUTH_GET_TOKEN = createRequestTypes("AUTH_GET_TOKEN");
export const AUTH_SET_TOKEN = createRequestTypes("AUTH_SET_TOKEN");
export const AUTH_SET_REFRESH_TOKEN = createRequestTypes("AUTH_SET_REFRESH_TOKEN");

export const GENERATE_SITE_MAP = createRequestTypes("GENERATE_SITE_MAP");
export const GET_BING_WEBMASTER_DATA = createRequestTypes("GET_BING_WEBMASTER_DATA");
export const GET_GOOGLE_WEBMASTER_DATA = createRequestTypes("GET_GOOGLE_WEBMASTER_DATA", {
    SUCCESS_HEADER_DATA: "SUCCESS_HEADER_DATA",
    SUCCESS_CHART_DATA: "SUCCESS_CHART_DATA",
    SUCCESS_ROWS_DATA: "SUCCESS_ROWS_DATA",
});
export const GET_SITEMAP_DATA = createRequestTypes("GET_SITEMAP_DATA", {
    SUCCESS_HEADER_DATA: "SUCCESS_HEADER_DATA",
    SUCCESS_CHART_DATA: "SUCCESS_CHART_DATA",
    SUCCESS_ROWS_DATA: "SUCCESS_ROWS_DATA",
});

/**
 * @author Alex Malyi <am@piogroup.net>
 * @desc Get token actions
 */
export const authGetToken = {
    request: request => ({ type: AUTH_GET_TOKEN.REQUEST, request }),
    success: response => ({ type: AUTH_GET_TOKEN.SUCCESS, response }),
    error:   error => ({ type: AUTH_GET_TOKEN.FAILURE, error })
};


export const setGoogleAuthToken = {
    request: request => ({ type: AUTH_SET_TOKEN.REQUEST, request }),
    success: response => ({ type: AUTH_SET_TOKEN.SUCCESS, response }),
    error:   error => ({ type: AUTH_SET_TOKEN.FAILURE, error })
};

export const setRefreshToken = {
    request: request => ({ type: AUTH_SET_REFRESH_TOKEN.REQUEST, request }),
    success: response => ({ type: AUTH_SET_REFRESH_TOKEN.SUCCESS, response }),
    error:   error => ({ type: AUTH_SET_REFRESH_TOKEN.FAILURE, error })
};

export const generateSiteMap = {
    request: request => ({ type: GENERATE_SITE_MAP.REQUEST, request }),
    success: response => ({ type: GENERATE_SITE_MAP.SUCCESS, response }),
    error:   error => ({ type: GENERATE_SITE_MAP.FAILURE, error })
};

export const getBingWebmasterData = {
    request: request => ({ type: GET_BING_WEBMASTER_DATA.REQUEST, request }),
    success: data => ({ type: GET_BING_WEBMASTER_DATA.SUCCESS, data }),
    error:   error => ({ type: GET_BING_WEBMASTER_DATA.FAILURE, error })
};

export const getGoogleWebmasterData = {
    request: request => ({ type: GET_GOOGLE_WEBMASTER_DATA.REQUEST, request }),
    success: response => ({ type: GET_GOOGLE_WEBMASTER_DATA.SUCCESS, response }),
    successHeaderData: response => ({ type: GET_GOOGLE_WEBMASTER_DATA.SUCCESS_HEADER_DATA, response }),
    successChartData: response => ({ type: GET_GOOGLE_WEBMASTER_DATA.SUCCESS_CHART_DATA, response }),
    successRowsData: response => ({ type: GET_GOOGLE_WEBMASTER_DATA.SUCCESS_ROWS_DATA, response }),
    error:   error => ({ type: GET_GOOGLE_WEBMASTER_DATA.FAILURE, error })
};

export const getSitemapData = {
    request: request => ({ type: GET_SITEMAP_DATA.REQUEST, request }),
    success: response => ({ type: GET_SITEMAP_DATA.SUCCESS, response }),
    successHeaderData: response => ({ type: GET_SITEMAP_DATA.SUCCESS_HEADER_DATA, response }),
    successChartData: response => ({ type: GET_SITEMAP_DATA.SUCCESS_CHART_DATA, response }),
    successRowsData: response => ({ type: GET_SITEMAP_DATA.SUCCESS_ROWS_DATA, response }),
    error:   error => ({ type: GET_SITEMAP_DATA.FAILURE, error })
};
