// @flow

import { call, put, take, fork } from "redux-saga/effects";
import http from "../../api";
import * as actions from "./actions";
import { Storage } from "helpers/index";
import { API } from "aws-amplify/lib/index";
import Auth from '@aws-amplify/auth'
import { GoogleApiHelper } from "helpers/index";
import { prepareHeaderData, prepareChartData } from "helpers";
import { prepareHeaderSitemapData } from "./components/sitemapStatusSection/sitemapStatusFunctions";
import qs from 'querystring'
import Route53 from 'aws-sdk/clients/route53';

const googleWebMaster = GoogleApiHelper.getWebmaster();
let authStore=localStorage.getItem("persist:root")

/**
 * @author Alex Malyi <am@piogroup.net>
 * @desc Get token request from API function
 */
function* watchGetToken() {
    while (true) {
        const { request } = yield take(actions.AUTH_GET_TOKEN.REQUEST);
        const url = `${request.originHost}/wp-json/jwt-auth/v1/token`;

        try {
            const response = yield call(http, url, {
                method: "POST",
                body: JSON.stringify({ ...request }),
            });

            if (!!response.token && response.token.length > 0){
                yield put(actions.authGetToken.success(response));
                yield Storage.setToken(response.token);
            } else {
                throw new Error("Something went wrong");
            }
        } catch (e) {
            yield put(actions.authGetToken.error(e));
        }
    }
}

/**
 * @author Alex Malyi <am@piogroup.net>
 * @desc Get token request from API function
 */
function* watchSetGoogleAuthToken() {
    while (true) {                                                                                                                                                                                                                                                                                                                                                                                          
        const { request } = yield take(actions.AUTH_SET_TOKEN.REQUEST);
       
      
        if(request) {
            const tokens = yield GoogleApiHelper.setTokens(request.code,request.type);
            
            if(tokens){
                yield GoogleApiHelper.storeToken(tokens);
                yield put(actions.setGoogleAuthToken.success(tokens));
            } else {
                throw new Error("Something went wrong");
            }
        }
    }
}


/**
 * @author Alex Malyi <am@piogroup.net>
 * @desc Get token request from API function
 */
function* watchSetRefreshAuthToken() {
    while (true) {
        const { request } = yield take(actions.AUTH_SET_REFRESH_TOKEN.REQUEST);
        
        console.log('request watchSetRefreshAuthToken >>>>', request)
        // console.log("AUTH current user",Auth)
        
        if(request.type == 'set-google-token' && authStore!==null &&  JSON.parse(JSON.parse( authStore).user).isAuthenticated ) {
        try{
                console.log("reuest token>>> ",request.token)
                const config = {
                    body: { ...request.token },
                    response: true,
                };
            
              const response = yield API.put("auctollo", "/site/tokens",config);
               yield GoogleApiHelper.storeToken(response.data.data.googletoken);
                yield put(actions.setGoogleAuthToken.success(response.data.data.googletoken));
          

        } catch(e){
            yield put(actions.setGoogleAuthToken.error(e))
        }
        }
        if(request.type == 'get-google-token' && authStore!==null &&  JSON.parse(JSON.parse( authStore).user).isAuthenticated    ){
            try{
                const config = {
                    body: { ...request.token },
                    response: true,
                };
                const response = yield API.get("auctollo", "/site/tokens",config);
                console.log('get-google-token',JSON.parse( response.data.body).data.googletoken)
                yield GoogleApiHelper.storeToken(JSON.parse( response.data.body).data.googletoken);

                
            } catch(e){
                console.log("set-google-token ERROR>>>>", e)
                yield put(actions.setGoogleAuthToken.error(e))
            }
            
        }
    }
}

/**
 * @author Alex Malyi <am@piogroup.net>
 * @desc Generate SiteMap request
 */
function* watchGenerateSiteMap() {
    while (true) {
        const { request } = yield take(actions.GENERATE_SITE_MAP.REQUEST);

        try {
            if (request) {
                const config = {
                    body: { ...request },
                    response: true,
                };

                const response = yield API.post("auctollo", "/sitemap/push-sitemap", config);

                if (response.status === 200 && response.data){
                    yield put(actions.generateSiteMap.success());
                }
            }
        } catch (e) {
            yield put(actions.generateSiteMap.error(e));
            throw new Error("Something went wrong");
        }
    }
}

/**
 * @author Alex Malyi <am@piogroup.net>
 * @desc Get Google Webmaster data from Search Console
 */
function* watchGetGoogleWebmasterData() {
    while (true) {
        let { request } = yield take(actions.GET_GOOGLE_WEBMASTER_DATA.REQUEST);
        try {
            if (request) {
                yield* watchGetGoogleWebmasterHeaderData(request);
                yield* watchGetGoogleWebmasterChartData(request);
                yield* watchGetGoogleWebmasterRowsData(request);
            }
        } catch (e) {
            yield put(actions.getGoogleWebmasterData.error(e));
            throw new Error("Something went wrong");
        }
    }
}

function* watchGetSitemapData() {
    while (true) {
        let { request } = yield take(actions.GET_SITEMAP_DATA.REQUEST);
        try {
            if (request) {
                yield* watchGetSitemapHeaderData(request);
                yield* watchGetSitemapRowsData(request);
            }
        } catch (e) {
            yield put(actions.getSitemapData.error(e));
            throw new Error("Something went wrong");
        }
    }
}


function* watchGetGoogleWebmasterHeaderData(request) {
    try {
        if (request){
            let googleWebMasterInstance = yield googleWebMaster
            let { data: { rows } } = yield googleWebMasterInstance.searchanalytics.query({
                siteUrl: request.siteUrl,
                requestBody: {
                    "startDate": request.startDate,
                    "endDate": request.endDate,
                    "rowLimit": 25000,
                    "dimensions": [
                        "date"
                    ]
                }
            });

            console.log('rows >>', rows)
            yield put(actions.getGoogleWebmasterData.successHeaderData({
                headerData: prepareHeaderData(rows),
            }));
        }
    } catch(e) {
        yield put(actions.getGoogleWebmasterData.error(e));
    }
}

function* watchGetGoogleWebmasterChartData(request) {
    try {
        if (request){
            let googleWebMasterInstance = yield googleWebMaster
            let { data: { rows } } = yield googleWebMasterInstance.searchanalytics.query({
                siteUrl: request.siteUrl,
                requestBody: {
                    "startDate": request.startDate,
                    "endDate": request.endDate,
                    "rowLimit": 25000,
                    "dimensions": [
                        "date"
                    ]
                }
            });
            yield put(actions.getGoogleWebmasterData.successChartData({
                chartData: prepareChartData(rows, request),
            }));
        }
    } catch(e) {
        yield put(actions.getGoogleWebmasterData.error(e));
    }
}

function* watchGetGoogleWebmasterRowsData(request) {
    try {
        if (request){
            let googleWebMasterInstance = yield googleWebMaster

            console.log('request >>>', request)
            let { data: { rows } } = yield googleWebMasterInstance.searchanalytics.query({
                siteUrl: request.siteUrl,
                requestBody: {
                    "dimensions": request.dimensions,
                    "startDate": request.startDate,
                    "endDate": request.endDate,
                    "rowLimit": request.rowLimit
                }
            });
            yield put(actions.getGoogleWebmasterData.successRowsData({
                rowsData: rows,
            }));
        }
    } catch(e) {
        yield put(actions.getGoogleWebmasterData.error(e));
    }
}

function* watchGetSitemapHeaderData(request) {
    try {
        if (request){
            let googleWebMasterInstance = yield googleWebMaster
            let { data: { sitemap } } = yield googleWebMasterInstance.sitemaps.list({
                siteUrl: request.siteUrl,
                sitemapIndex: request.sitemapIndex,
            });
            yield put(actions.getSitemapData.successHeaderData({
                headerData: prepareHeaderSitemapData(sitemap),
            }));
        }
    } catch(e) {
        yield put(actions.getSitemapData.error(e));
    }
}

function* watchGetSitemapRowsData(request) {
    try {
        if (request){
            let googleWebMasterInstance = yield googleWebMaster
            let { data: { sitemap } } = yield googleWebMasterInstance.sitemaps.list({
                siteUrl: request.siteUrl,
                sitemapIndex: request.sitemapIndex,
            });
            yield put(actions.getSitemapData.successRowsData({
                rowsData: sitemap,
            }));
        }
    } catch(e) {
        yield put(actions.getSitemapData.error(e));
    }
}

export default [
    fork(watchGetToken),
    fork(watchGenerateSiteMap),
    fork(watchGetGoogleWebmasterData),
    fork(watchGetSitemapData),
    fork(watchSetGoogleAuthToken),
    fork(watchSetRefreshAuthToken)
];
