// @flow
const { REACT_APP_GOOGLE_CLIENT_ID, REACT_APP_GOOGLE_CLIENT_SECRET, REACT_APP_OAUTH_REDIRECT_URL } = process.env;
import { GOOGLE_TOKENS } from "constants/index";
import { Storage } from "helpers/index";
import { google } from "googleapis";

/**
 * @author Roman Yephimov <ry@piogroup.net>
 * @desc Component for work with Google API
 */
class GoogleAPI {

    /**
     * @author Roman Yephimov <ry@piogroup.net>
     * @desc Google webmaster instance
     */
    static googleWebMaster = null;

    static oAuthClient = new google.auth.OAuth2(
        REACT_APP_GOOGLE_CLIENT_ID,
        REACT_APP_GOOGLE_CLIENT_SECRET,
        REACT_APP_OAUTH_REDIRECT_URL,
    );

    static scopes = [
        "https://www.googleapis.com/auth/webmasters",
        "https://www.googleapis.com/auth/webmasters.readonly",
        "https://www.googleapis.com/auth/indexing",
        "https://www.googleapis.com/auth/analytics.readonly",
        "https://www.googleapis.com/auth/userinfo.email"
    ];

    /**
     * @author Roman Yephimov <ry@piogroup.net>
     * @desc Function which returns URL for authorization
     * @return { String } - Auth URL
     */
    static getAuthUrl = () => this.oAuthClient.generateAuthUrl({
        access_type: "offline",
        scope: this.scopes.join(" "),
    });

    /**
     * @author Roman Yephimov <ry@piogroup.net>
     * @desc Function for setting token to oauth client
     */
    static setTokens = async (code: string, type:string) => {
        const { tokens } =  await this.oAuthClient.getToken(code);
        if(tokens) {
            Storage.setItem(GOOGLE_TOKENS, tokens);
            this.oAuthClient.setCredentials(tokens);
            this.googleWebMaster = google.webmasters({
                version: "v3",
                auth: this.oAuthClient,
            });
            return tokens
        }
    };

    /**
     * @author Roman Yephimov <ry@piogroup.net>
     * @desc Function which returns webmaster instance
     */
    static getWebmaster = async () => {
        const tokens = Storage.getItem(GOOGLE_TOKENS);
       this.oAuthClient.setCredentials(tokens);
        if(this.googleWebMaster === null) {
            if(await this.isAuthorized) {
                this.googleWebMaster = google.webmasters({
                    version: "v3",
                    auth: this.oAuthClient,
                });
            } else {
                return null;
            }
        }
        return this.googleWebMaster;
    };

    /**
     * @author Roman Yephimov <ry@piogroup.net>
     * @desc Function which returns webmaster instance
     */
    static isAuthorized = () => {
         return google.oauth2('v2').tokeninfo({
                auth: this.oAuthClient
            }).then(response => {
                if(response.data.verified_email) {
                    return true
                } else {
                    return false
                }
            }).catch(err=>{
                console.log('Not Authorized', err)
                return false
            })
    };

   static getUserInfo = () => {
        return google.oauth2('v2').userinfo.get({
            auth: this.oAuthClient,
        }).then(response => {
            return response
        }).catch(err=>{
            console.log('err', err)
            return err
        })
    };


    static storeToken = (tokens: Object) => {
        if(tokens) {
            Storage.setItem(GOOGLE_TOKENS, tokens);
            this.oAuthClient.setCredentials(tokens);
            this.googleWebMaster = google.webmasters({
                version: "v3",
                auth: this.oAuthClient,
            });
        }
    };

    static getUserSitesList = async () => {
        if (! await this.isAuthorized) return;
        const sites = await this.getWebmaster();
        return sites && sites.sites && await sites.sites.list();
    };

    static getIndexingData = async (site: string) => {
        if (! await this.isAuthorized) return;
        const { access_token } = Storage.getItem(GOOGLE_TOKENS) || {};
        return await fetch(`https://indexing.googleapis.com/v3/urlNotifications/metadata?url=${encodeURI(site)}&access_token=${access_token}`);
    };
}

export default GoogleAPI;
