// @flow
import React, { Component } from "react";

//styles
import global from "assets/scss/global.module.scss";
import styles from "./chartDropdown.module.scss";

//types
import type { ChartDropdownPropType, ChartDropdownStateType } from "./ChartDropdownType";

class ChartDropdown extends Component<ChartDropdownPropType, ChartDropdownStateType> {
    state = {
        listOpen: false,
        headerTitle: "select",
        chartDropdownConfig: this.props.list
    }
    handleClick = (event: SyntheticEvent<HTMLButtonElement>) => {
        this.setState({
            headerTitle: event.currentTarget.textContent,
            listOpen: false
        });
    };

    toggleList(){
        this.setState({
            listOpen: !this.state.listOpen
        });
    }
    render(){
        return(
            <div className={styles["chart-dropdown"]}>
                <div className={`${global["d-flex"]}`}>Show:
                    <span
                        className={`${styles["title"]} ${styles[this.state.listOpen && "opened"]}`}
                        onClick={() => this.toggleList()}>{this.state.headerTitle} <i className={styles["arrow-icon"]}></i></span>
                </div>
                {this.state.listOpen && <div className={`${global["list"]} ${global["bg-white"]} ${styles["list"]}`}>
                    {this.state.chartDropdownConfig.map((item, i) => (
                        <button className={styles["list-item"]} key={i} onClick={this.handleClick}>{item.title}</button>
                    ))}
                </div>}
            </div>
        );
    }
}

export default ChartDropdown;
