// @flow
import React from "react";
import { withRouter, Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";

//types
import type { LogoPropType } from "./LogoType.js";

//styles
import styles from "./logo.module.scss";

const Logo = ({ user: { sites, activeSite }, changeActiveDomain, history }: LogoPropType) => {
    const onChangeActiveSite = (event, site) => {
        event.preventDefault();
        changeActiveDomain(site);
    };

    return (
        <React.Fragment>
            <button
                type="button"
                onClick={() => { history.push("/"); }}
                className={styles.logo}
            >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 120 24" width="120" height="24">
                    <path
                        fill="#165362"
                        d="M30.898 15.006c0-2.993 4.879-3.688 9.407-4.004v-.48c0-1.111-.95-1.844-3.465-1.844-2.076 0-3.84.733-4.703 1.301l-.838-1.15c.875-.694 2.814-1.578 5.729-1.578 3.54 0 4.953 1.25 4.953 3.511v4.535c0 1.263.076 1.768.263 2.324h-1.713c-.138-.366-.213-.732-.226-1.44-.938.846-3.252 1.655-5.541 1.655-2.377.013-3.866-.884-3.866-2.83zm9.407-.025v-2.703c-4.265.341-7.73.91-7.73 2.627 0 .998.85 1.58 2.74 1.58 2.038 0 4.34-.885 4.99-1.504zM44.096 14.526v-7.06h1.714v6.808c0 1.364 1.063 2.071 3.09 2.071 1.726 0 3.677-.707 4.565-1.503V7.465h1.701v10.169h-1.713v-1.39c-.651.556-2.89 1.592-5.217 1.592-2.49.012-4.14-.872-4.14-3.31zM57.043 12.568c0-3.915 3.365-5.33 6.23-5.33 2.877 0 4.79 1.111 5.616 2.98l-1.651.519c-.688-1.289-2.052-2.021-4.003-2.021-2.327 0-4.478 1.225-4.478 3.84 0 2.147 1.788 3.84 4.403 3.84 1.501 0 3.315-.581 4.153-2.135l1.614.581c-.876 1.705-3.09 2.994-5.88 2.994-3.177.012-6.004-1.857-6.004-5.268zM72.703 14.577V8.855h-3.277v-1.39h3.277V3.878h1.714v3.6h4.316v1.39h-4.316v5.38c0 1.693.688 2.097 1.964 2.097.65 0 1.889-.139 2.264-.214v1.402c-.288.063-1.689.252-2.84.252-2.151-.012-3.102-1.035-3.102-3.208zM79.72 12.568c0-3.65 3.003-5.33 6.28-5.33 3.278 0 6.305 1.667 6.305 5.33 0 3.575-3.04 5.28-6.305 5.28-3.252 0-6.28-1.705-6.28-5.28zm10.859 0c0-2.286-1.864-3.84-4.566-3.84-2.69 0-4.553 1.504-4.553 3.84 0 2.249 1.85 3.84 4.553 3.84 2.714 0 4.566-1.54 4.566-3.84zM94.27 3.41h1.688v14.224H94.27V3.41zM98.547 3.41h1.689v14.224h-1.69V3.41zM102.201 12.568c0-3.65 3.002-5.33 6.28-5.33 3.277 0 6.304 1.667 6.304 5.33 0 3.575-3.039 5.28-6.304 5.28-3.253 0-6.28-1.705-6.28-5.28zm10.858 0c0-2.286-1.864-3.84-4.566-3.84-2.689 0-4.553 1.504-4.553 3.84 0 2.249 1.851 3.84 4.553 3.84 2.715 0 4.566-1.54 4.566-3.84zM116.297 8.122h-.813V7.92h1.864v.202h-.813v1.604h-.225l-.013-1.604zM117.574 7.92h.313l.901 1.566.9-1.566h.3v1.806h-.225V8.173l-.875 1.553h-.213l-.876-1.54v1.54h-.212V7.92h-.013z"
                    />
                    <path
                        fill="#3EACA2"
                        d="M11.884 24c6.563 0 11.883-5.373 11.883-12S18.447 0 11.884 0C5.32 0 0 5.373 0 12s5.32 12 11.884 12z"
                    />
                    <path
                        fill="#fff"
                        d="M6.129 14.754c0-2.994 4.879-3.689 9.407-4.004v-.48c0-1.112-.95-1.845-3.465-1.845-2.077 0-3.84.733-4.704 1.301l-.838-1.15c.876-.694 2.815-1.578 5.73-1.578 3.54 0 4.953 1.25 4.953 3.512v4.534c0 1.264.075 1.769.263 2.325H15.76c-.138-.367-.213-.733-.225-1.44-.938.846-3.253 1.654-5.542 1.654-2.377.013-3.865-.884-3.865-2.83zm9.407-.025v-2.704c-4.266.341-7.73.91-7.73 2.628 0 .998.85 1.579 2.739 1.579 2.039 0 4.34-.884 4.99-1.503z"
                    />
                </svg>
            </button>
            <div>
                <Dropdown id="site-dropdown">
                    <Dropdown.Toggle id="site-dropdown-toggle">
                        {activeSite && activeSite.domain}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        {/* {sites && sites.length > 0 && sites.map(item => (
                            <a className="dropdown-item" key={item.domain} onClick={event => onChangeActiveSite(event, item)} href="#">{item.domain}</a>
                        ))} */}
                        {sites && sites.length > 0 && 
                            <a className="dropdown-item" key={sites[0].domain} onClick={event => onChangeActiveSite(event, sites[0])} href="#">{sites[0].domain}</a>
                        }
                        <Dropdown.Divider />
                        <Link className="dropdown-item" to="/add-domains">Add site</Link>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </React.Fragment>
    );
};

export default withRouter(Logo);
