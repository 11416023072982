export const googleWebmasterHeaderConfig = [
    {
        type: "totalClick",
        title: "Total Clicks",
        value: "---",
        iconComponent: null,
        colClass: "col-md-3",
        labelConfig: "", //"active" or "warning"
        labelText: "--",
    },
    {
        type: "averageViews",
        title: "Average Views Daily",
        value: "---",
        iconComponent: null,
        colClass: "col-md-3",
        labelConfig: "",
        labelText: "--",
    },
    {
        type: "averageCtr",
        title: "Average CTR",
        value: "---",
        iconComponent: null,
        colClass: "col-md-3",
        labelConfig: "",
        labelText: "--",
    },
    {
        type: "averagePosition",
        title: "Average Position",
        value: "---",
        iconComponent: null,
        colClass: "col-md-3",
        labelConfig: "",
        labelText: "--",
    },
];

export const sitemapStatusHeaderConfig = [
    {
        type: "submittedSitemaps",
        title: "Submitted Sitemaps",
        value: "---",
        iconComponent: null,
        colClass: "col-md-6",
        labelConfig: "", //"active" or "warning"
        labelText: "--",
    },
    {
        type: "indexedSitemaps",
        title: "Indexed Sitemaps",
        value: "---",
        iconComponent: null,
        colClass: "col-md-6",
        labelConfig: "",
        labelText: "--",
    },
];
