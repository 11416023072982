//@flow

import React from "react";
import { Heading, ButtonPrimary } from "common/components";

import global from "../../../assets/scss/global.module.scss";

import type { CompleteSetupTypes } from "./CompleteSetupTypes";

const CompleteSetup = (props: CompleteSetupTypes) => {
    const { authorize } = props;
    return (
        <div className={`${global["d-flex"]} ${global["flex-column"]} ${global["align-items-center"]} ${global["justify-content-center"]}`}>
            <Heading
                align="center"
                title="Complete Your Setup"
                subtitle="To view this data you will need to complete your setup with Google Analytics." />
            <ButtonPrimary
                buttonSize="medium"
                buttonText="Connect Google"
                action={authorize}
            />
        </div>
    );
};

export default CompleteSetup;
