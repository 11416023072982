//@flow

import React from "react";
import Label from "./../label/Label";
import global from "../../../assets/scss/global.module.scss";
import styles from "./cardStatistic.module.scss";

import type { CardStatisticType } from "./CardStatisticType";

const CardStatistic = (props: CardStatisticType) => {
    return (
        <div
            className={`${styles["card"]} ${global["d-flex"]} ${global["flex-column"]} ${global["align-items-center"]} ${styles[props.withIcon && "with-icon"]}`}>
            <div className={styles["card-heading"]}>
                {props.heading}
            </div>
            <p className={styles["card-subtitle"]}>
                {props.subtitle}
            </p>
            { props.label && <Label
                labelConfig={props.labelConfig}
                labelIcon={props.labelIcon}
                labelText={props.labelText} /> }
        </div>
    );
};

export default CardStatistic;
