// @flow

import React, { Component } from "react";
import { Logo } from "..";
import Navigation from "./navigation/Navigation";
import Dropdown from "./dropdown/Dropdown";
import global from "../../../assets/scss/global.module.scss";
import styles from "./topPanel.module.scss";

import type { TopPanelPropType, TopPanelStateType } from "./TopPanelType.js";

class TopPanel extends Component<TopPanelPropType, TopPanelStateType> {
    state = {
        mobileNav: false
    }

    toggleMobileNav = () => this.setState({ mobileNav: !this.state.mobileNav });

    render() {
        const { user } = this.props;
        console.log('user', user)
        return (
            <div className={`${global["bg-white"]} ${global["with-shadow"]} ${styles["panel"]}`}>
                <div className={`${global["d-flex"]} ${global["justify-content-between"]} ${global["align-items-center"]} ${styles["panel-content"]}`}>
                    <div className={`${global["d-flex"]} ${styles["panel-left"]}`}>
                        <Logo {...this.props}/>
                        <h4 className={`${global["d-flex"]} ${global["title"]} ${styles["panel-title"]}`}>Sitemaps</h4>
                    </div>
                    <div className={`${global["d-flex"]} ${styles["panel-right"]}`}>
                        <div className={`${styles["toggle-nav"]} ${styles[this.state.mobileNav && "active"]}`} onClick={this.toggleMobileNav}>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                        <div className={`${styles["nav-wrapper"]} ${styles[this.state.mobileNav && "open"]}`}>
                            {
                                this.props.isAuthenticated ?
                                    <Dropdown handleLogout={this.props.handleLogout} user={user}/> :
                                    <Navigation/>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default TopPanel;
