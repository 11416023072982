// @flow
import React from "react";
import { ButtonPrimary } from "..";
import { CheckIcon } from "../../../assets/icons";
import styles from "./pricingPlanCard.module.scss";
import global from "../../../assets/scss/global.module.scss";

import type { PricingPlanCardTypes, HandleActionButtonType } from "./PricingPlanCardTypes";

const ACCOUNT_ACTIVE_TEXT = "Account active";

const handleActionButton = ({ type, push, url }: HandleActionButtonType) => {
    push({
        pathname: url,
        state: { type: type }
    });
};

const PricingPlanCard = (props: PricingPlanCardTypes) => {
    const { icon, title, type, subtitle, buttonText, pricingPlan, push, url, isAuthenticated } = props;

    const isCurrentActive = pricingPlan === type;
    const text = isCurrentActive ? ACCOUNT_ACTIVE_TEXT : buttonText;

    return (
        <div className={`${global["bg-white"]} ${global["d-flex"]} ${global["with-shadow"]} ${styles.card}`}>
            {isCurrentActive &&
                <div className={`${global["d-flex"]} ${styles["icon-checked"]}`}>
                    <CheckIcon />
                </div>
            }
            <div className={`${global["d-flex"]} ${styles["card-icon"]}`}>
                {icon}
            </div>
            <div className={styles["card-body"]}>
                <h4 className={`${global.title} ${styles["card-title"]}`}>
                    {title}
                </h4>
                <h5 className={`${global.title} ${global["card-subtitle"]} ${styles["card-subtitle"]}`}>
                    {subtitle}
                </h5>
                <div className={styles["card-action"]}>
                    {type=="enterprise"?
                    <a href="mailto:sales@auctollo.com">
                    <ButtonPrimary
                    buttonText={text}
                    buttonSize="large"
                    // action={() => handleActionButton({ type, push, url })}
                    disabled={isCurrentActive}
                    btnCenter=""/>
                    </a>
                    :
                    <ButtonPrimary
                        buttonText={text}
                        buttonSize="large"
                        action={title=="Premium" && isAuthenticated==false ?
                        () => handleActionButton({ type, push, url:"/sign-up" })
                        : () => handleActionButton({ type, push, url })}
                        disabled={isCurrentActive}
                        btnCenter=""
                    />
                }
                </div>
            </div>
        </div>
    );
};

export default PricingPlanCard;
