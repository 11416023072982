import { createRequestTypes } from "helpers/constants";

export const SIGN_IN = createRequestTypes("SIGN_IN", { SIGN_UP_REDIRECT: "SIGN_UP_REDIRECT" });
export const SOCIAL_LOGIN = createRequestTypes("SOCIAL_LOGIN");

/**
 * @author Roman Yephimov <ry@piogroup.net>
 * @desc Sign In actions
 */
export const signIn = {
    request: request => ({ type: SIGN_IN.REQUEST, request }),
    success: () => ({ type: SIGN_IN.SUCCESS }),
    error:   error => ({ type: SIGN_IN.FAILURE, error }),
    signUpRedirect: () => ({ type: SIGN_IN.SIGN_UP_REDIRECT })
};

export const socialSignIn = {
    request: request => ({ type: SOCIAL_LOGIN.REQUEST, request }),
    success: response => ({ type: SOCIAL_LOGIN.SUCCESS, response }),
    error:   error => ({ type: SOCIAL_LOGIN.FAILURE, error })
};
