// @flow
import { put, take, fork, call } from "redux-saga/effects";
import { Auth, API } from "aws-amplify";
import * as actions from "./actions";
import * as userActions from "actions/user";
import config from "./config";
import { stopSubmit } from "redux-form";
import { awsAuth, wpDomainsData } from "../../config/offlineData";
import { GoogleApiHelper } from "helpers/index";


//types
import type { IOEffect } from "redux-saga/effects";

function* watchSignUp(): Generator<IOEffect, void, any> {
    
    while (true) {
        const { request: { email, password, first_name, action } } = yield take(actions.SIGN_UP.REQUEST);
        const formId = config.signupUnconfirmedForm.id;
        try {
            if(process.env.REACT_APP_MOCK === 'true'){
                yield put(userActions.setUserData({
                    username: email,
                    attributes: {
                        name: `${first_name}`
                    }
                }));
            } else {
                 const { user } = yield Auth.signUp({
                    username: email,
                    password,
                    attributes: {
                        name: `${first_name}`
                    },
                });
                yield put(userActions.setUserData(user));
            }
            yield put(actions.signUp.success());
            yield call(action, "/sign-up/code-verify");
        } catch (e) {
            yield put(actions.signUp.error(e.message));
            yield put(stopSubmit(formId, e.message));
        }
    }
}

function* watchConfirmUser(): Generator<IOEffect, void, any> {
    while (true) {
        const { request: { confirmationCode, user, password, action } } = yield take(actions.CONFIRM_USER.REQUEST);
        const formId = config.codeVerify.id;
        try {
            const { username } = user.userData;
            if(process.env.REACT_APP_MOCK === 'true'){
                const { userDataKey, attributes, Session } = awsAuth;
                yield put(userActions.setUserData({ username, userDataKey, attributes, Session }));
                yield put(actions.confirmUser.success());
            } else {
                yield Auth.confirmSignUp(username, confirmationCode);
                const { userDataKey, attributes, Session } = yield Auth.signIn(username, password);
                yield put(userActions.setUserData({ username, userDataKey, attributes, Session }));
                yield put(actions.confirmUser.success());
            }
            yield call(action, "/sign-up/wp-verify");
        } catch (e) {
            yield put(actions.confirmUser.error(e.message));
            yield put(stopSubmit(formId, e.message));
        }
    }
}

function* watchAuthGetDomains(): Generator<IOEffect, void, any> {
    while (true) {
        const { request: { wpUsername, wpPassword, wpDomain, action, url } } = yield take(actions.AUTH_GET_DOMAINS.REQUEST);
        const formId = config.wpVerify.id;
        try {
            const reqBody = {
                body: {
                    wpUsername,
                    wpPassword,
                    wpDomain,
                }
            };
            let res = {}
            if(process.env.REACT_APP_MOCK === 'true'){
               res =  wpDomainsData
            } else {
                res = yield API.post("auctollo", "/site/wp-domains", reqBody);
            }
            const { data } = res;
            console.log(data)
            yield put(actions.authGetDomains.success(data || {}));
            yield action(data || {});
            yield call(action, url);
        } catch (e) {
            if (e && e.message) {
                yield put(actions.authGetDomains.error(e.message));
                yield put(stopSubmit(formId, e.message));
            } else {
                yield put(actions.authGetDomains.error(e));
                yield put(stopSubmit(formId, e));
            }
        }
    }
}

function* watchChooseDomains(): Generator<IOEffect, void, any> {
    while (true) {
        const { request: { domains, action, isRegistered } } = yield take(actions.CHOOSE_DOMAINS.REQUEST);
        try {
            const shapedDomains = [];
            domains.forEach(item => {
                item && shapedDomains.push({
                    domain: item.domain,
                    key: item.key
                });
            });
            const reqBody = {
                body: { domains: shapedDomains, isRegistered },
            };
            let response = {}
            if(process.env.REACT_APP_MOCK === 'true'){
               response =  {"data":{}}
            } else {
               response = yield API.post("auctollo", "/site/create", reqBody);
            }
            if (response.data) {
                yield put(userActions.setDomainsData(domains));
                yield put(userActions.setActiveDomain(domains[0]));
                yield call(action, "/");
                let isUI_Login = yield window.localStorage.getItem('amplify-signin-with-hostedUI');

                console.log('isUI_Login', isUI_Login)
                if(isUI_Login === 'true' || isUI_Login === true){
                    yield window.location.replace(GoogleApiHelper.getAuthUrl());
                }
                // yield call(action, "/payment-braintree");
                yield put(actions.chooseDomains.success());
            } else {
                yield put(actions.chooseDomains.error("Something went wrong"));
            }
        } catch (e) {
            yield put(actions.chooseDomains.error(e.message));

        }
    }
}

export default [
    fork(watchSignUp),
    fork(watchConfirmUser),
    fork(watchAuthGetDomains),
    fork(watchChooseDomains),
];
